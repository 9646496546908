import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './UnsubscribeEmailView.css';	
import { addEmailToBlacklist } from '../../api/email_blacklists/EmailBlacklistService';

const UnsubscribeEmailView: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('e');

    useEffect(() => {
        if (!email) {
            navigate('/');
            return;
        }
    }, [email, navigate]);

    const handleClick = async () => {
        // Add your logic here
        try {
            const response = await addEmailToBlacklist({ email: email as string });
        }catch (error) {
            console.error('Error adding email to blacklist:', error);
        } finally {
            navigate('/');
        }
    };


    return (
        <div className='unsubscribe-email-view'>
            <div className="unsubscribe-inner-view">
                <h1 className="unsubscribe-title">Meld je af voor de nieuwsbrief</h1>
                <p className="unsubscribe-txt-p">Je kunt je hier definitief afmelden voor de 10-Things nieuwsbrief</p>

                <div className="unsubscribe-inner-block">
                    <p className="unsubscribe-txt-p">Je staat op het punt om je uit te schrijven voor de nieuwsbrief met het volgende e-mailadres:<br></br><br></br><b>{email}</b></p>
                    <div className="unsubscribe-btns">
                        <button className="unsubscribe-btn" onClick={handleClick}>Afmelden voor nieuwsbrief</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UnsubscribeEmailView;