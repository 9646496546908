import React, { useState } from 'react';
import { Link } from 'react-router-dom';

interface LoginFormProps {
    // Add any props you need for the LoginForm component
    handleSubmit: (event: React.FormEvent) => void;
    email: string;
    handleEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    emailError: string;
    password: string;
    handlePasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    passwordError: string;
    isLoading: boolean;
}

const LoginForm: React.FC<LoginFormProps> = ({handleSubmit, email, handleEmailChange, emailError, password, handlePasswordChange, passwordError, isLoading }) => {

    return (
        <form id="inloggen-login-form" onSubmit={handleSubmit}>
            <div id='emailinput-bar' className="inloggen-login-form-input-bar">
                <label htmlFor="login-email-input" className="inloggen-login-label">E-mail</label>
                <input type="text" className="inloggen-login-input" id='login-email-input' value={email} onChange={handleEmailChange} />
                <div id='login-email-err' className="login-form-error-field">{emailError}</div>
            </div>

            <div id='passwordinput-bar' className="inloggen-login-form-input-bar">
                <label htmlFor="login-password-input" className="inloggen-login-label">Wachtwoord</label>
                <input type="password" className="inloggen-login-input" id='login-password-input' value={password} onChange={handlePasswordChange} />
                <div id='login-password-err' className="login-form-error-field">{passwordError}</div>
            </div>

            <div className="inloggen-login-form-input-bar">
                <div className="inloggen-login-form-left-area">
                    <label id="login-remember-me-checkbox">
                        <input type="checkbox" id="login-remember-checkbox-input" />
                        <span className="checkmark"></span>
                        <span className="checkbox-text">Onthoud mij</span>
                    </label>
                </div>
                <div className="inloggen-login-form-right-area">
                    <Link to='/wachtwoord-vergeten'>
                        <p className="inloggen-login-forget-pass-txt-p">Wachtwoord vergeten?</p>
                    </Link>
                </div>
            </div>

            <div className="inloggen-login-form-input-bar">
                <button type="submit" className="inloggen-login-button">
                    {isLoading ? (
                        <span className="progress"></span>
                    ) : (
                        <div id="login-txt-div">Aanmelden</div>
                    )}
                </button>
            </div>

            <div className="inloggen-login-form-inpur-bar">
                <Link to={"/register"}>
                    <button className="inloggen-register-btn"><div id="login-txt-div">Geen account? Registreer hier</div></button>
                </Link>
            </div>

        </form>
    );
};

export default LoginForm;