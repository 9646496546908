import React, { useEffect, useState } from 'react';
import './DeBriefingModal.css';
import { Shift } from '../../../../models/Shift';
import RecapView from './InnerViews/RecapView/RecapView';
import GreenNoteView from './InnerViews/GreenNoteView/GreenNoteView';
import OrangeNoteView from './InnerViews/OrangeNoteView/OrangeNoteView';
import YellowNoteView from './InnerViews/YellowNoteView/YellowNoteView';
import GradeView from './InnerViews/GradeView/GradeView';
import { fetchReportKpis } from '../../../../api/reportkpis/reportKpiService';
import { ReportKpi } from '../../../../models/ReportKpi';
import { getSubsidiary } from '../../../../api/auth/user-info';
import { addReport, addReportNotes, addReportThings, generateReport } from '../../../../api/day_reports/day_reportsService';
import { updateShiftDB } from '../../../../api/shift/shiftService';
import { deleteDBAction } from '../../../../api/actions/actionsService';
import LoadingOverlay from '../../LoadingOverlay/LoadingOverlay';

interface DeBriefingModalProps {
    onClose: () => void;
    shift: Shift | null;
    refreshShiftKey: number;
    setRefreshShiftKey: (key: number) => void;
    clearShift: () => void;
}

const DeBriefingModal: React.FC<DeBriefingModalProps> = ({ onClose, shift, refreshShiftKey, setRefreshShiftKey, clearShift }) => {

    const stopPropagation = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    const [greenNoteTxt, setGreenNoteTxt] = useState<string | undefined>(
        shift?.notes.find(note => note.note_type === 1)?.note_txt ?? undefined
    );
    const [orangeNoteTxt, setOrangeNoteTxt] = useState<string | undefined>(
        shift?.notes.find(note => note.note_type === 2)?.note_txt ?? undefined
    );
    const [yellowNoteTxt, setYellowNoteTxt] = useState<string | undefined>(
        shift?.notes.find(note => note.note_type === 3)?.note_txt ?? undefined
    );

    const [date, setDate] = React.useState<Date>(new Date());
    const formattedDate = date.toISOString().slice(0, 10);

    const [step, setStep] = useState<number>(1);

    const prevStep = () => {
        setStep(step - 1);
    };

    const nextStep = () => {
        if (validateStep(step)) {
            setStep(step + 1);
        }
    };

    const validateStep = (currentStep: number): boolean => {
        switch (currentStep) {
            case 1:
                let noError = false;
                if (recap.length > 0) {
                    noError = true;
                    setRecapError('');
                } else {
                    setRecapError('Je moet wel een rapportage invullen!');
                }
                return noError; // Example: always passing validation for step 1
            case 2:
                return true;
            case 3:
                return true;
            case 4:
                return true;
            case 5:
                return true;
            // Add more cases for additional steps
            default:
                return false; // Default to failing validation if step is unknown
        }
    };

    const handleSubmit = () => {
        // Add submit logic here
        onClose();
    };

    const [recap, setRecap] = useState<string>('');
    const [recapError, setRecapError] = useState<string>('');

    const RecapViewProps = {
        // Add props for RecapView here
        recap: recap,
        setRecap: setRecap,
        recapError: recapError,
    };

    const GreenNoteViewProps = {
        note_type: 1,
        greenNoteTxt: greenNoteTxt!,
        setGreenNoteTxt: setGreenNoteTxt
    };

    const OrangeNoteViewProps = {
        note_type: 2,
        orangeNoteTxt: orangeNoteTxt!,
        setOrangeNoteTxt: setOrangeNoteTxt
    };

    const YellowNoteViewProps = {
        note_type: 3,
        yellowNoteTxt: yellowNoteTxt!,
        setYellowNoteTxt: setYellowNoteTxt
    };

    const [reportKpis, setReportKpis] = useState<ReportKpi[]>([]);

    useEffect(() => {
        const loadReportKpis = async () => {
            try {
                const response = await fetchReportKpis();
                console.log(response);
                setReportKpis(response);
            } catch (error) {
                console.log(error);
            } finally {
                console.log('Fetched reportKpis:', reportKpis);
            }
        }
        loadReportKpis();
    }, []);

    const [kpiValues, setKpiValues] = useState<{ [key: string]: number }>({});
    const [totalGrade, setTotalGrade] = useState<number>(1);

    const GradeViewProps = {
        reportKpis: reportKpis,
        kpiValues: kpiValues,
        setKpiValues: setKpiValues,
        totalGrade: totalGrade,
        setTotalGrade: setTotalGrade
    };

    const [report_id, setReportId] = useState<string>('');

    const handleDaySubmit = async () => {
        setLoading(true);
        try {
            const subsidiaryID = getSubsidiary().id;
            const response = await addReport({
                subsidiary_id: subsidiaryID,
                manager: shift!.manager,
                grade: Number(totalGrade),
                final_report: recap,
                date: new Date().toISOString().slice(0, 10),
                additional_inputs: JSON.stringify(kpiValues),
                shift_id: shift!.id,
            });

            console.log('Response:', response);

            const report_id = response.id;

            if (greenNoteTxt) {
                await addReportNotes({
                    report_id: report_id,
                    note_txt: greenNoteTxt,
                    note_date: new Date(),
                    note_type: 1
                });
            }

            if (orangeNoteTxt) {
                await addReportNotes({
                    report_id: report_id,
                    note_txt: orangeNoteTxt,
                    note_date: new Date(),
                    note_type: 2
                });
            }

            if (yellowNoteTxt) {
                await addReportNotes({
                    report_id: report_id,
                    note_txt: yellowNoteTxt,
                    note_date: new Date(),
                    note_type: 3
                });
            }

            shift!.actions.forEach(async (action) => {
                await addReportThings({
                    thing_type_id: action.thing_type_id,
                    report_id: report_id,
                    title: action.title,
                    description: action.description,
                    comments: action.comments,
                    done_date: action.completed_on
                });
            });


            const updateShiftResponse = await updateShiftDB(shift!.id, { is_active: false });
            console.log('UpdateShiftResponse:', updateShiftResponse);

            await generateReport(report_id);

        } catch (error) {
            setLoading(false);
            console.error('Failed to add report:', error);
        } finally {
            setRefreshShiftKey(refreshShiftKey + 1);
            clearShift();
            onClose();
            setLoading(false);
        }
    };

    const handleShiftSubmit = async () => {
        setLoading(true);
        try {
            const subsidiaryID = getSubsidiary().id;
            const response = await addReport({
                subsidiary_id: subsidiaryID,
                manager: shift!.manager,
                grade: Number(totalGrade),
                final_report: recap,
                date: new Date().toISOString().slice(0, 10),
                additional_inputs: JSON.stringify(kpiValues),
                shift_id: shift!.id,
            });

            console.log('Response:', response);

            if (greenNoteTxt) {
                await addReportNotes({
                    report_id: response.id,
                    note_txt: greenNoteTxt,
                    note_date: new Date(),
                    note_type: 1
                });
            }

            if (orangeNoteTxt) {
                await addReportNotes({
                    report_id: response.id,
                    note_txt: orangeNoteTxt,
                    note_date: new Date(),
                    note_type: 2
                });
            }

            if (yellowNoteTxt) {
                await addReportNotes({
                    report_id: response.id,
                    note_txt: yellowNoteTxt,
                    note_date: new Date(),
                    note_type: 3
                });
            }

            shift!.actions.forEach(async (action) => {
                await deleteDBAction(action.id);
                await addReportThings({
                    thing_type_id: action.thing_type_id,
                    report_id: response.id,
                    title: action.title,
                    description: action.description,
                    comments: action.comments,
                    done_date: action.completed_on
                });
            });


            const updateShiftResponse = await updateShiftDB(shift!.id, { is_active: false });
            console.log('UpdateShiftResponse:', updateShiftResponse);
            await generateReport(response.id);
        } catch (error) {
            setLoading(false);
            console.error('Failed to add report:', error);
        } finally {
            setRefreshShiftKey(refreshShiftKey + 1);
            clearShift();
            onClose();
            setLoading(false);
        }
    };

    const [loading, setLoading] = React.useState<boolean>(false);

    return (
        <div className='de-briefing-modal-overlay' >
            {loading && <LoadingOverlay />}
            <div className="de-briefing-modal-wrapper">
                <div className="de-briefing-modal-container" onClick={stopPropagation}>
                    <div className="de-briefing-top-bar">
                        <div className="de-briefing-right-area">
                            <h2 className="de-briefing-title">De-briefing</h2>
                        </div>
                        <div className="de-briefing-middle-area">
                        </div>
                        <div className="de-briefing-left-area">
                            <button className="de-briefing-close-btn" onClick={onClose}>
                                <span className="material-symbols-rounded close-icon" translate='no'>close</span>
                            </button>
                        </div>
                    </div>
                    <div className="de-briefing-content-area">
                        {step === 1 && <RecapView {...RecapViewProps} />}
                        {step === 2 && <GreenNoteView {...GreenNoteViewProps} />}
                        {step === 3 && <OrangeNoteView {...OrangeNoteViewProps} />}
                        {step === 4 && <YellowNoteView {...YellowNoteViewProps} />}
                        {step === 5 && <GradeView {...GradeViewProps} />}
                        <div className="de-briefing-controls-area">
                            {step < 5 &&
                                < div className="de-briefing-form-control-buttons">
                                    {step > 1 && step < 5 && <button className='half-submit-buttons' onClick={prevStep} type='button'><span className="material-symbols-rounded submit-icon" translate='no'>arrow_back</span></button>}
                                    {step < 5 && <button className='half-submit-buttons' onClick={nextStep} type='button'><span className="material-symbols-rounded submit-icon" translate='no'>arrow_forward</span></button>}
                                </div>}
                            {step === 5 && (<div className="de-briefing-send-report-controls">
                                <button className="de-briefing-send-report-prev-btn" onClick={prevStep}>
                                    <span className="material-symbols-rounded submit-icon" translate='no'>arrow_back</span>
                                </button>
                                <button className="de-briefing-send-report-btn shift" onClick={handleShiftSubmit}>
                                    Voltooi shift
                                </button>

                                {/* <button className="de-briefing-send-report-btn day" onClick={handleDaySubmit}>
                                    Voltooi dag
                                </button> */}
                            </div>)}

                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default DeBriefingModal;