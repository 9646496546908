import React, { useEffect } from 'react';
import './ThingsStep.css';
import ThingsListPreBriefing from '../../ThingsListPreBriefing/ThingsListPreBriefing';
import { Action, ActionPreBriefing } from '../../../../../../models/Action';
import { fetchThingsByThingType, fetchThingTypes } from '../../../../../../api/things/thingsService';
import { fetchActionsByDate } from '../../../../../../api/actions/actionsService';
import ViewCreatedThingsPreBriefing from '../../ThingsStep/ViewCreatedThingsPreBriefing/ViewCreatedThingsPreBriefing';
import { ThingType } from '../../../../../../models/ThingType';
import useInspireMe from '../../../../../../utils/useInspireMe';
import InspireMeThingItem from '../../ThingsStep/ViewCreatedThingsPreBriefing/InspireMeThingItem/InspireMeThingItem';
import { getSubsidiary } from '../../../../../../api/auth/user-info';
import { InspireMeOutput } from '../../../../../../models/InspireMe';
import AddThingPreBriefingModal from '../../AddThingPreBriefingModal/AddThingPreBriefingModal';
import ModalLayout from '../../../../ModalLayout/ModalLayout';

interface ThingsStepProps {
    // Add any props you need for the component here
    thingTypes: ThingType[];
    selectedLabelIds: number[];
    actions: any[];
    setActions: React.Dispatch<React.SetStateAction<any[]>>;
    activeThingIndex: number;
    setActiveThingIndex: React.Dispatch<React.SetStateAction<number>>;
}

function capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const ThingsStep: React.FC<ThingsStepProps> = ({ thingTypes, selectedLabelIds, actions, setActions, activeThingIndex, setActiveThingIndex }) => {
    // Add your component logic here

    const subsidiary = getSubsidiary();

    const [date, setDate] = React.useState<Date>(new Date());
    const formattedDate = date.toISOString().slice(0, 10);

    const [things, setThings] = React.useState<any[]>([]);

    const [thingsRefreshKey, setThingsRefreshKey] = React.useState<number>(0);

    const [activeThingType, setActiveThingType] = React.useState<ThingType>(thingTypes[activeThingIndex]);

    useEffect(() => {
        const loadThings = async () => {
            try {
                const things = await fetchThingsByThingType(thingTypes[activeThingIndex].id);
                console.log('Things:', things);
                setThings(things);
            } catch (err) {
                console.error('Failed to fetch things:', err);
            } finally {
                console.log('Done fetching things');
            }
        };
        loadThings();
    }, [activeThingIndex, thingsRefreshKey]);


    const inspiration = useInspireMe(selectedLabelIds, activeThingType.id, 10);

    let amountOfActionOfType = actions.filter(action => action.thing_type_id === activeThingType?.id).length;

    const onPlanThingClick = (thing: any) => {
        if (amountOfActionOfType >= 2) {
            return;
        }

        const planAction: any = {
            id: 0,
            thing_type_id: activeThingType.id,
            thing_id: thing.id,
            title: thing.title,
            description: thing.description,
            completed_on: undefined,
            date: formattedDate,
            is_planned: true,
            is_pinned: true,
            from_template: false,
            subsidiary_id: subsidiary.id,
        }
        inspiration.splice(inspiration.findIndex(insp => insp.id === thing.id), 1);
        setThings(things.filter(t => t.id !== thing.id));
        addAction(planAction);
    };

    const addAction = (action: any) => {
        console.log('Add action:', action);
        setActions(prevActions => {
            const updatedActions = [...prevActions, action];
            amountOfActionOfType = updatedActions.filter(action => action.thing_type_id === activeThingType.id).length;
            console.log('Amount of actions of type:', amountOfActionOfType);
            if (amountOfActionOfType === 2) {
                if (activeThingIndex === thingTypes.length - 1) {
                    return updatedActions;
                }
                setActiveThingIndex(activeThingIndex + 1);
                setActiveThingType(thingTypes[activeThingIndex + 1]);
            }
            console.log('Updated actions:', updatedActions);
            return updatedActions;
        });


    }

    useEffect(() => {
        console.log('Actions state updated:', actions);


    }, [actions]);

    const handleThingTypeClick = (index: number) => {
        setActiveThingIndex(index);
        setActiveThingType(thingTypes[index]);
    }

    const handleThingClick = (index: number) => {
        const clickedAction = actions.filter((action) => action.thing_type_id === activeThingType.id)[index];
        const clickedActionIndex = actions.findIndex((action) => action === clickedAction);
        console.log('Clicked action:', clickedAction);
        console.log('Actions', actions);

        // Remove the clickedAction from actions
        const updatedActions = actions.filter((_, i) => i !== clickedActionIndex);
        setActions(updatedActions);
    }

    const [openAddThingPreBriefingModal, setOpenAddThingPreBriefingModal] = React.useState<boolean>(false);

    const handleRefreshThings = () => {
        setThingsRefreshKey(thingsRefreshKey + 1);
    }



    return (
        <div className='pre-briefing-things-step'>
            {openAddThingPreBriefingModal && <ModalLayout title={`Voeg een ${activeThingType.singular.toLowerCase()} toe`} isOpen={openAddThingPreBriefingModal} onClose={() => setOpenAddThingPreBriefingModal(false)}>
                <AddThingPreBriefingModal thingtype={activeThingType} closeAddThingModal={() => setOpenAddThingPreBriefingModal(false)} refreshThings={handleRefreshThings} />
            </ModalLayout>}
            <div className="pre-briefing-things-list-area">
                <ThingsListPreBriefing thingtypes={thingTypes} actions={actions} activeThingType={activeThingType} handleThingTypeClick={handleThingTypeClick} handleThingClick={handleThingClick} />
            </div>
            {actions.length < 10 && <div className="pre-briefing-selection-area">
                <h2 className="pre-briefing-things-select-title-h2">Selecteer jouw {capitalizeFirstLetter(activeThingType.plural)} voor vandaag!</h2>
                <p className="pre-briefing-things-select-txt-p">Je maximaal 2 {capitalizeFirstLetter(activeThingType.plural)} kiezen om in te plannen.</p>
                <div className="pre-briefing-selection-types-area">
                    <div className="pre-briefing-selection-recommended-area">
                        <h4 className="pre-briefing-selection-title-h4">Selecteer voorgestelde {capitalizeFirstLetter(activeThingType.plural)}</h4>
                        {inspiration.map((insp, index) => (
                            <InspireMeThingItem key={index} thing={{
                                id: insp.id,
                                title: insp.title,
                                description: insp.description
                            }} thingtype={thingTypes[activeThingIndex]} onPlanThingClick={onPlanThingClick} />
                        ))}
                    </div>

                    <div className="pre-briefing-selection-custom-area">
                        <h4 className="pre-briefing-selection-title-h4">Selecteer uit je eigen {capitalizeFirstLetter(activeThingType.plural)}</h4>
                        <ViewCreatedThingsPreBriefing thingtype={thingTypes[activeThingIndex]} onAddThingClick={() => setOpenAddThingPreBriefingModal(true)} refreshKey={activeThingIndex} onPlanThingClick={onPlanThingClick} things={things} setThings={setThings} />
                    </div>
                </div>
            </div>}
        </div>
    );
};

export default ThingsStep;