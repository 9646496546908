import React, { useEffect, useState } from 'react';
import './VIPWidget.css';
import { Vip, VipBriefing } from '../../../../../models/Vip';
import VipWidgetItem from '../../../VIPWidget/VipWidgetItem/VipWidgetItem';
import { Shift } from '../../../../../models/Shift';

interface VIPWidgetProps {
    // Je kunt hier eventueel props definiëren als je ze nodig hebt
    setOpenAddVIPModal: (open: boolean) => void;
    vips: Vip[];
    activeShift: Shift | null;
    onVipOptionsClick: (event: React.MouseEvent, vip: VipBriefing | Vip) => void;
}

const VIPWidget: React.FC<VIPWidgetProps> = ({ setOpenAddVIPModal, vips, activeShift, onVipOptionsClick }) => {
    return (
        <div className='vip-widget'>
            <div className="vip-widget-top-bar">
                <div className="vip-widget-inner-title-area">
                    <p className="VIP-widget-txt">VIPs van de dag</p>
                </div>
                {activeShift !== null && <button className="vip-widget-add-btn" onClick={() => setOpenAddVIPModal(true)}>
                    <span className="vip-widget-add-icon-span material-symbols-outlined">add</span>
                </button>}
            </div>

            {vips.length === 0 && (
                <div className="vipwidget-no-vip-div">
                    <p className="vip-widget-no-vips-txt">Er zijn geen VIPs voor vandaag</p>
                </div>)}

            {vips.length > 0 && (<div className="vip-widget-list">

                {vips.map((vip, index) => (
                    <VipWidgetItem key={index} vip={vip} onVipOptionsClick={onVipOptionsClick} />
                ))}
            </div>)}
        </div>
    );
};

export default VIPWidget;
