import axios from "axios";
import { CreateShift, Shift, UpdateShift } from "../../models/Shift";
import api from "../auth/api";
import { getSubsidiary } from "../auth/user-info";
import { decreateCredits } from "../../utils/creditUtils";

export const fetchShift = async (id: number): Promise<Shift> => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/shifts/${id}/`;
        const response = await api.get(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch shift:', error);
        throw error;
    }
}

export const fetchShifts = async (): Promise<Shift[]> => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = '/shifts/';
        const response = await api.get(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch shifts:', error);
        throw error;
    }
}

export const fetchActiveShift = async (): Promise<Shift | null> => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const subsidiary = getSubsidiary();
        const url = `/shifts/active/${subsidiary.id}/`;
        const response = await api.get(url, config);
        if (response.status === 200) {
            return response.data;
        } else {
            console.warn('Unexpected status code:', response.status);
            return null;
        }
    } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 404) {
            console.warn('Active shift not found (404). Returning null.');
            return null;
        } else {
            console.error('Failed to fetch active shift:', error);
            throw error;
        }
    }
};

export const addShift = async (shift: CreateShift) => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    };
    try {
        const url = `/shifts/`;
        const response = await api.post(url, shift, config);
        return response.data;
    } catch (error) {
        console.error('Failed to add shift:', error);
        throw error;
    }
};

export const updateShiftDB = async (shift_id: number, shift: UpdateShift) => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    };

    try {
        const url = `/shifts/${shift_id}/`;
        const response = await api.put(url, shift, config);
        return response.data;
    } catch (error) {
        console.error('Failed to update shift:', error);
        throw error;
    }
    
};

export const generateBriefingShift = async (shift_id: number) => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    };

    try {
        const url = `/shifts/${shift_id}/generate_pdf/`;
        const response = await api.get(url, config);
        await decreateCredits();
        return response.data;
    } catch (error) {
        console.error('Failed to generate briefing:', error);
        throw error;
    }
}

export const fetchShiftsBySubsidiary = async () => {
    const subsidiary = getSubsidiary();
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/shifts/by_subsidiary/${subsidiary.id}/`;
        const response = await api.get(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch shifts by subsidiary:', error);
        throw error;
    }
}