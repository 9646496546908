import React from 'react';
import './LoadingOverlay.css';
import { Spinner } from '@nextui-org/react';

const LoadingOverlay: React.FC = () => {
    return (
        <div className="loading-overlay">
            <div className="loading-overlay-inner-div">
                <Spinner size="lg" />
            </div>
        </div>
    );
};

export default LoadingOverlay;