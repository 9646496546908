import axios from 'axios';
import { getUserInfo } from './user-info';
import { apiURL } from '../../devEnv';

export const loginUser = async (username: string, password: string) => {
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);

    try {
        const response = await axios.post(`${apiURL}/auth/login/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        const data = response.data;
        window.localStorage.setItem('tokens', JSON.stringify(data));
        await retrieveUserInfo(data.id, data.access_token);

        return { status: response.status, data };
    } catch (error) {
        console.error('Error:', error);
        if (axios.isAxiosError(error) && error.response) {
            throw { status: error.response.status, message: error.response.statusText, data: error.response.data };
        }
        throw error;
    }
};

export const retrieveUserInfo = async (id: string, accessToken: string) => {
    console.log(`Retrieving user info ${id} with token ${accessToken}`);
    return fetch(`${apiURL}/users/${id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    }).then(response => {
        if (response.status !== 200) {
            throw new Error(response.statusText);
        }
        return response.json();
    }
    ).then(data => {
        console.log(data);
        window.localStorage.setItem('user_info', JSON.stringify(data));
        if (data.subsidiaries.length !== 0) {
            window.localStorage.setItem('subsidiary', JSON.stringify(data.subsidiaries[0]));
        }
        console.log(data.subsidiaries[0]);
    }).catch((error) => {
        console.error('Error:', error);
        throw error;
    });

};

export const checkLocalStorageItems = async () => {
    const user = window.localStorage.getItem('user_info');
    const tokens = window.localStorage.getItem('tokens');
    const subsidiary = window.localStorage.getItem('subsidiary');
    if (user && tokens && subsidiary) {
        return true;
    } else {
        return false;
    }
};

export const refreshAccessToken = async () => {
    let userInfo = JSON.parse(localStorage.getItem('user_info')!)
    let tokens = JSON.parse(localStorage.getItem('tokens')!)
    if (!tokens.refresh_token || !userInfo.username) {
        throw new Error("No refresh token or username available.");
    }
    try {
        const response = await axios.post(
            `${apiURL}/auth/refresh/`,
            { username: userInfo.username, refresh_token: tokens.refresh_token }
        );
        const newAccessToken = response.data.access_token;
        tokens.access_token = newAccessToken;
        console.log(tokens);
        localStorage.setItem('tokens', JSON.stringify(tokens));
        return newAccessToken;
    } catch (error) {
        console.error('Failed to refresh token:', error);
        throw error;
    }
};

export const logoutUser = async () => {
    let userInfo = JSON.parse(localStorage.getItem('user_info')!);
    let tokens = JSON.parse(localStorage.getItem('tokens')!);
    if (!tokens.refresh_token || !userInfo.username) {
        throw new Error("No refresh token or username available.");
    }
    try {
        const response = await axios.post(
            `${apiURL}/auth/signout/${tokens.access_token}/`,
            { username: userInfo.username, refresh_token: tokens.refresh_token }
        );
        localStorage.removeItem('user_info');
        localStorage.removeItem('uid');
        localStorage.removeItem('tokens');
        localStorage.removeItem('subsidiary');
        return response.data;
    } catch (error) {
        console.error('Failed to logout:', error);
        throw error;
    }
};

export const deleteUser = async () => {
    const id = getUserInfo().id;
    let tokens = JSON.parse(localStorage.getItem('tokens')!);
    if (!tokens.access_token) {
        throw new Error("No access token available.");
    }
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/auth/delete_user/${id}/`,
            { headers: { 'Authorization': `Bearer ${tokens.access_token}` } }
        );
        return response.data;
    } catch (error) {
        console.error('Failed to delete user:', error);
        throw error;
    }
}