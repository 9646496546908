import './App.css';
import './input.css';
import './output.css';
import RoutesApp from './Routes.tsx';
import * as React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { NextUIProvider } from "@nextui-org/react";
import { HelmetProvider } from 'react-helmet-async';



function App() {
  return (
    <HelmetProvider>
      <NextUIProvider>
        <Router>
          <RoutesApp />
        </Router>
      </NextUIProvider>
    </HelmetProvider>
  );
}

export default App;
