import React, { useEffect, useState } from 'react';
import PortaalPageLayout from '../../../components/Portaal/PageLayout/PageLayout';
import CalendarView from '../../../components/Portaal/Planning/CalendarView/CalendarView';
import CalendarTopBar from '../../../components/Portaal/Planning/CalendarTopBar/CalendarTopBar';
import CalendarMonthView from '../../../components/Portaal/Planning/CalendarMonthView/CalendarMonthView';
import { fetchActionsByDate, fetchActionsBySubsidiaryDateShiftType } from '../../../api/actions/actionsService';
import ModalLayout from '../../../components/Portaal/ModalLayout/ModalLayout';
import AddPlanThingModal from '../../../components/Portaal/Things/AddPlanThingModal/AddPlanThingModal';
import { fetchThingTypes } from '../../../api/things/thingsService';
import CompleteThingModal from '../../../components/Portaal/Things/CompleteThingModal/CompleteThingModal';
import ActionOptionsOverlay from '../../../components/Portaal/Action/ActionOptionsOverlay/ActionOptionsOverlay';
import { Action } from '../../../models/Action';
import AddEventModal from '../../../components/Portaal/Planning/AddEventModal/AddEventModal';
import { Shift } from '../../../models/Shift';
import { fetchActiveShift } from '../../../api/shift/shiftService';
import { act } from 'react-dom/test-utils';
import { Subscription } from '../../../models/Subscription';

interface PlanningViewProps {
    toggleNav: () => void;
    isNavOpen: boolean;
    handleOpenBuyCreditsModal: () => void;
    openBuyCreditsModal: boolean;
    setOpenBuyCreditsModal: React.Dispatch<React.SetStateAction<boolean>>;
    activeSubscription: Subscription | null | undefined;
}

const PlanningView: React.FC<PlanningViewProps> = ( props ) => {
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    console.log('TESTTTTTTTTT', selectedDate);
    const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
    const [currentWeek, setCurrentWeek] = useState<Date>(new Date());
    const [agendaType, setAgendaType] = useState<string>('week');

    const [activeShift, setActiveShift] = useState<Shift | null>(null);

    const [refreshShiftKey, setRefreshShiftKey] = useState(0);

    const refreshShift = () => {
        setRefreshShiftKey(prevKey => prevKey + 1);
    };

    useEffect(() => {
        const loadActiveShift = async () => {
            const activeShift = await fetchActiveShift();
            if (activeShift === null) {
                return;
            }
            setActiveShift(activeShift);
            setActions(activeShift.actions);
            console.log('Active shift:', activeShift);
        };
        loadActiveShift();
    }, [refreshShiftKey]);

    const toggleAgendaType = () => {
        setAgendaType(agendaType === 'week' ? 'month' : 'week');
    };

    const handleNextButtonClick = () => {
        console.log('selectedDate: ', selectedDate);
    if (agendaType === 'month') {
        setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1));
    } else {
        let newSelectedDate;
        if (selectedDate.getDay() === 0) {
            newSelectedDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + 1);
            setSelectedDate(newSelectedDate);
            console.log('New selected date:', newSelectedDate);
            setCurrentWeek(new Date(newSelectedDate.getFullYear(), newSelectedDate.getMonth(), newSelectedDate.getDate() + 7));
        } else {
            newSelectedDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + 1);
            setSelectedDate(newSelectedDate);
        }

        setCurrentMonth(new Date(newSelectedDate.getFullYear(), newSelectedDate.getMonth(), 1));

        refreshActions();
    }
};

const handlePreviousButtonClick = () => {
    if (agendaType === 'month') {
        setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1));
    } else {
        let newSelectedDate;
        if (selectedDate.getDay() === 1) {
            newSelectedDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() - 1);
            setSelectedDate(newSelectedDate);
            console.log('New selected date:', newSelectedDate);
            setCurrentWeek(new Date(newSelectedDate.getFullYear(), newSelectedDate.getMonth(), newSelectedDate.getDate() - 7));
        } else {
            newSelectedDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() - 1);
            setSelectedDate(newSelectedDate);
        }

        setCurrentMonth(new Date(newSelectedDate.getFullYear(), newSelectedDate.getMonth(), 1));

        refreshActions();
    }
};

    const [refreshActionsKey, setRefreshActionsKey] = useState(0);

    const refreshActions = () => {
        setRefreshActionsKey(prevKey => prevKey + 1);
    };

    const [addingThingType, setAddingThingType] = useState<any>(undefined);

    const onClickAddButton = (thingType: any) => {
        setAddingThingType(thingType);
        setOpenPlanThingModal(true);
    };

    const onAddThingClick = () => {
    };

    const onPlanThingClick = (thing: any) => {
    };

    const [refreshThingsKey, setRefreshThingsKey] = useState(0);

    const refreshThings = () => {
        setRefreshThingsKey(prevKey => prevKey + 1);
    };

    const [openPlanThingModal, setOpenPlanThingModal] = useState<boolean>(false);

    const closeAddPlanThingModal = () => {
        setOpenPlanThingModal(false);
    };

    const [thingTypes, setThingTypes] = React.useState<any[]>([]);
    const [actions, setActions] = React.useState<any[]>([]);

    var formattedDate = selectedDate.toISOString().slice(0, 10);

    useEffect(() => {
        const loadThingTypes = async () => {
            try {
                const thingTypes = await fetchThingTypes();
                setThingTypes(thingTypes);
            } catch (err) {
                console.error('Failed to fetch thing types:', err);
            } finally {
            }
        };

        loadThingTypes();
    }, []);

    const addActions = async (addingActions: any[]) => {
        console.log('Adding action:', addingActions);
        setActions([...actions, ...addingActions]);
    };

    const deleteAction = async (action_id: number) => {
        const updatedActions = actions.filter((action) => action.id !== action_id);
        setActions(updatedActions);
    };

    useEffect(() => {
        console.log("Actions: ", actions);
    }, [actions]);

    const [openCompleteThingModal, setOpenCompleteThingModal] = useState<boolean>(false);

    const [selectedAction, setSelectedAction] = useState<any>(undefined);

    const closeCompleteThingModal = () => {
        setOpenCompleteThingModal(false);
    };

    const onClickAction = (action: any) => {
        console.log(action);
        setSelectedAction(action);
        setOpenCompleteThingModal(true);
    }

    const [openActionOptionsOverlay, setOpenActionOptionsOverlay] = useState<boolean>(false);
    const [actionOptionsX, setActionOptionsX] = useState<number>(0);
    const [actionOptionsY, setActionOptionsY] = useState<number>(0);
    const [selectedActionOptionsAction, setSelectedActionOptionsAction] = useState<Action>();
    
    const onActionOptionsClick = (event: React.MouseEvent, action: Action) => {
        event.stopPropagation();
        const rect = (event.target as HTMLElement).getBoundingClientRect();
        setActionOptionsX(rect.left);
        setActionOptionsY(rect.bottom);
        setOpenActionOptionsOverlay(true);
        setSelectedActionOptionsAction(action);
    };

    const [openAddEventModal, setOpenAddEventModal] = useState<boolean>(false); 

    return (
        <div>
            {openAddEventModal && <AddEventModal onClose={() => setOpenAddEventModal(false)} />}
            { openCompleteThingModal &&<ModalLayout isOpen={openCompleteThingModal} onClose={() => setOpenCompleteThingModal(false)}>
                <CompleteThingModal action={selectedAction} closeCompleteThingModal={closeCompleteThingModal}/>
            </ModalLayout>}
            {openActionOptionsOverlay && <ActionOptionsOverlay onClose={() => setOpenActionOptionsOverlay(false)} x={actionOptionsX} y={actionOptionsY} selectedActionOptionsAction={selectedActionOptionsAction} deleteAction={deleteAction} />}
            {openPlanThingModal && <ModalLayout isOpen={openPlanThingModal} onClose={() => setOpenPlanThingModal(false)}>
                <AddPlanThingModal thingtype={addingThingType} onAddThingClick={onAddThingClick} onPlanThingClick={onPlanThingClick} refreshKey={refreshThingsKey} selectedDate={selectedDate} closeAddPlanThingModal={closeAddPlanThingModal} addActions={addActions} />
            </ModalLayout>}
            <PortaalPageLayout activePage='planning' isNavOpen={props.isNavOpen} toggleNav={props.toggleNav} handleOpenBuyCreditsModal={props.handleOpenBuyCreditsModal} openBuyCreditsModal={props.openBuyCreditsModal} setOpenBuyCreditsModal={props.setOpenBuyCreditsModal} activeSubscription={props.activeSubscription}>
                <CalendarTopBar toggleAgendaType={toggleAgendaType} handleNextButtonClick={handleNextButtonClick} handlePreviousButtonClick={handlePreviousButtonClick} currentMonth={currentMonth} setOpenAddEventModal={setOpenAddEventModal}/>
                {agendaType === 'month' && <CalendarMonthView currentMonth={currentMonth} />}
                {agendaType === 'week' && <CalendarView currentWeek={currentWeek} selectedDate={selectedDate} setSelectedDate={setSelectedDate} refreshActions={refreshActions} onClickAddButton={onClickAddButton} thingTypes={thingTypes} actions={actions} onClickAction={onClickAction} onActionOptionsClick={onActionOptionsClick} />}
            </PortaalPageLayout>
        </div>
        
    );
};

export default PlanningView;