import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../../assets/img/website-10things-logo.png';
import './PayedPaymentInfoView.css';
import { Transaction } from '../../../../models/Transaction';
import { Product } from '../../../../models/Product';
import { SubscriptionType } from '../../../../enums/SubscriptionType';
import { ProductType } from '../../../../enums/ProductType';

interface PayedPaymentInfoViewProps {
    product: Product;
    transaction: Transaction;
    amount: number;
    vat: number;
    total: number;
}

enum RecurringType {
    MONTHLY = 1,
    ANNUAL = 2,
    ONE_TIME = 3
}

const PayedPaymentInfoView: React.FC<PayedPaymentInfoViewProps> = (props) => {
    // Implement the component logic here

    const amountInEuro = props.amount.toLocaleString('de-DE', {
        minimumFractionDigits: 2, // Adjust as needed for decimal places
        maximumFractionDigits: 2, // Adjust as needed for decimal places
    });

    const vatInEuro = props.vat.toLocaleString('de-DE', {
        minimumFractionDigits: 2, // Adjust as needed for decimal places
        maximumFractionDigits: 2, // Adjust as needed for decimal places
    });

    const totalInEuro = props.total.toLocaleString('de-DE', {
        minimumFractionDigits: 2, // Adjust as needed for decimal places
        maximumFractionDigits: 2, // Adjust as needed for decimal places
    });

    const paymentDate = new Date(props.transaction.created_at);
    const formattedDate = paymentDate.toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' });
    const getRenewalDate = () => {
        switch (props.product.recurring_type) {
            case SubscriptionType.MONTHLY:
                paymentDate.setMonth(paymentDate.getMonth() + 1);
                return paymentDate.toLocaleDateString();
            case SubscriptionType.ANNUAL:
                paymentDate.setFullYear(paymentDate.getFullYear() + 1);
                return paymentDate.toLocaleDateString();
            default:
                return null;
        }
    };

    const getStartDate = () => {
        switch (props.product.recurring_type) {
            case SubscriptionType.MONTHLY:
                paymentDate.setDate(paymentDate.getDate() + 7);
                return paymentDate.toLocaleDateString();
            case SubscriptionType.ANNUAL:
                paymentDate.setDate(paymentDate.getDate() + 7);
                return paymentDate.toLocaleDateString();
            default:
                return null;
        }
    }

    let url = '';	
    if(props.product.product_type === ProductType.SUBSCRIPTION) {
        url = '/portaal/instellingen?m=subscription';
    } else {
        url = '/portaal/dashboard';
    }

    return (
        <div>
            <div className="payment-wrapper">
                <div className="payment-inner-content-div">
                    <img src={logo} alt="10Things logo" className="payment-logo-img" />
                    <svg className="checkmarkdone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                        <circle className="checkmark__circle" cx="34" cy="34" r="40" fill="none" />
                        <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" stroke-width="3" stroke-linecap="round" />
                    </svg>

                    <h2 id="betaling-pay-content-title-h2">Bedankt voor uw betaling</h2>
                    <br />
                    <p id="payment-confirmed-txt-p">We hebben uw betaling ontvangen en deze wordt momenteel verwerkt door ons systeem. U ontvangt binnenkort een e-mailbevestiging van uw betaling.<br></br> Heeft u vragen over onze service? Neem dan gerust contact met ons op.</p>

                    <div id="payment-confirmed-box-div">
                        <p id="payment-confirmation-title-txt-p">Betaling voldaan</p>
                        <p id="payment-confirmation-under-txt-p"><span className="payment-confirm-var-span">Factuurnummer:</span> {props.transaction.transaction_identifier}<br />
                            <span className="payment-confirm-var-span">Betalingsdatum: </span> {formattedDate}</p>
                    </div>

                    <ul className="payment-products-listing">
                        <li className="payment-product-item-li">
                            <div className="payment-product-img-area">
                                <div className="payment-app-icon-div">
                                    <img src={logo} alt="" className="payment-product-img" />
                                </div>
                            </div>
                            <div className="payment-product-info-area">
                                <p className="payment-product-item-name-p">{props.product.product_type! === ProductType.SUBSCRIPTION ? `${props.product.name}` : `${props.transaction.amount_of_products} x ${props.product.name}`}</p>
                                <p className="payment-product-item-info-p">{props.product.description}</p>
                                {props.product.product_type! === ProductType.SUBSCRIPTION && <p className="payment-product-item-renew-p"><b>Abonnement start op {getStartDate()}</b></p>}
                                {props.product.product_type! === ProductType.SUBSCRIPTION && <p className="payment-product-item-renew-p"><b>Wordt verlengd op {getRenewalDate()}</b></p>}
                            </div>
                            <div className="payment-product-pricing-area">
                                <p className="payment-product-item-total-p">€ {amountInEuro}</p>
                                <p className="payment-product-item-btw-p">Inclusief btw op 21% <span className="payment-product-item-btw-amount-span">€ {vatInEuro}</span></p>
                            </div>
                        </li>
                    </ul>
                    <div className="payment-total-area">
                        <div className="payment-total-bar-area">
                            <div className="payment-total-left-area">
                                <p className="payment-total-left-p">Subtotaal</p>
                            </div>
                            <div className="payment-total-right-area">
                                <p className="payment-total-right-p">€ {amountInEuro}</p>
                            </div>
                        </div>
                        <div className="payment-total-bar-area">
                            <div className="payment-total-left-area">
                                <p className="payment-total-left-p">BTW 21%</p>
                            </div>
                            <div className="payment-total-right-area">
                                <p className="payment-total-right-p">€ {vatInEuro}</p>
                            </div>
                        </div>
                        <div className="payment-total-bar-area">
                            <div className="payment-total-left-area">
                                <p className="payment-total-left-p total-txt">Totaal</p>
                            </div>
                            <div className="payment-total-right-area">
                                <p className="payment-total-right-p total-txt">€ {totalInEuro}</p>
                            </div>
                        </div>
                    </div>
                    <Link to={url}>
                        <button id="payment-confirmed-butt" type='button'>
                            <span id="payment-confirmed-butt-txt-span">Terug naar de website</span>
                            <span id="payment-confirmed-butt-icon-span" className="material-symbols-rounded">arrow_forward</span>
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default PayedPaymentInfoView;