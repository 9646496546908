import { getSubsidiary, updateSubsidiaryInfo } from "../api/auth/user-info";
import { fetchActiveSubscriptionByParentCompany } from "../api/subscriptions/subscriptionService";
import { updateSubsidiary } from "../api/subsidiary/subsidiaryService";

export async function decreateCredits() {
    const subsidiary = getSubsidiary();
    const amount = subsidiary.credits;

    await updateSubsidiary(subsidiary.id, { credits: amount - 1 });
    updateSubsidiaryInfo({ credits: amount - 1 });
}

export async function checkHasCredits() {
    const subsidiary = getSubsidiary();

    try {
        const activeSubscription = await fetchActiveSubscriptionByParentCompany();
        if (activeSubscription) {
            return true;
        }
    } catch (error) {
        
    }
    
    return subsidiary.credits > 0;
}