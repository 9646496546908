import React, { useState, useEffect, useRef } from 'react';
import './TopNavBar.css';
import { checkLocalStorageItems, logoutUser } from '../../../api/auth/user.auth';
import { Link, useNavigate } from 'react-router-dom';
import { getUserInfo } from '../../../api/auth/user-info';
import ActiveShiftBar from '../Dashboard/ActiveShiftBar/ActiveShiftBar';
import { Shift } from '../../../models/Shift';
import { Shifts } from '../../../enums/Shifts';
import ActiveManagerBar from '../Dashboard/ActiveManagerBar/ActiveManagerBar';
import CreditsBar from '../Dashboard/CreditsBar/CreditsBar';
import { Subscription } from '../../../models/Subscription';

interface TopNavBarProps {
    onBackButtonClick?: () => void;
    enableBackButton?: boolean;
    toggleNav: () => void;
    activeShift?: Shift | null;
    handleOpenBuyCreditsModal?: () => void;
    activeSubscription: Subscription | null | undefined;
}

function useClickOutside(ref: any, callback: any) {
    useEffect(() => {
        function handleClickOutside(event: { target: any; }) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, callback]);
}

const TopNavBar: React.FC<TopNavBarProps> = ({ onBackButtonClick = () => { },
    enableBackButton = false, toggleNav, activeShift, handleOpenBuyCreditsModal, activeSubscription }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null); // Add type assertion here

    let activeShiftType: number;

    if (activeShift !== null && activeShift !== undefined) {
        // console.log(activeShift);
        activeShiftType = activeShift!.shift_type;
    }

    useClickOutside(dropdownRef, () => setIsOpen(false));

    const navigate = useNavigate();


    const handleLogout = async () => {
        await logoutUser();
        checkLocalStorageItems().then((result) => {
            console.log(result);
            if (!result) {
                navigate('/login');
            }
        }
        );
    };

    var userOBJ = getUserInfo();

    return (
        <nav className="main-top-navbar-nav">
            <ul id="main-top-navbar-listing-ul">
                <li id="main-top-left-area">
                    {!enableBackButton && <button className="mobile-menu-btn" onClick={toggleNav} type='button'>
                        <span className="main-top-left-icon-span material-symbols-outlined">menu</span>
                    </button>}
                    {enableBackButton && (<button className="main-top-left-btn" onClick={onBackButtonClick}>
                        <span className="main-top-left-icon-span material-symbols-outlined">chevron_left</span>
                    </button>)}
                </li>
                <li id="main-top-center-area">
                    {/* <div className="main-top-searchbar-wrapper">
                        <input type="text" className="main-top-searchbar-input" placeholder="Zoeken..." />
                        <button className="main-top-searchbar-btn">
                            <span className="main-top-searchbar-icon-span material-symbols-outlined">search</span>
                        </button>
                    </div> */}

                    {activeShift !== null && activeShift !== undefined && <ActiveShiftBar shift={activeShift} shiftType={activeShift!.shift_type} />}


                </li>
                <li id="main-top-right-area">
                    {!activeSubscription && <CreditsBar handleOpenBuyCreditsModal={handleOpenBuyCreditsModal} />}

                    <div className="main-btn-wrapper">
                        <button className="main-top-btn-div user-btn" onClick={() => setIsOpen(!isOpen)}>
                            <p className="main-btn-txt-p">{userOBJ.firstname[0]}{userOBJ.lastname[0]}</p>
                        </button>
                        {isOpen && (
                            <div id="main-user-dropdown-div">
                                <div className="main-user-dropdown-menu-section">
                                    <div id="main-user-dropdown-top-info-txt-area">
                                        <p id="main-user-menu-name-txt">{userOBJ.firstname} {userOBJ.lastname}</p>
                                        <p id="main-user-menu-email-txt">{userOBJ.email}</p>
                                    </div>
                                </div>
                                <div className="main-user-dropdown-menu-section">
                                    <ul className="main-user-dropdown-menu-btns-listing-ul">
                                        <li className="main-user-dropdown-menu-btn-item-li">
                                            <Link to="/portaal/instellingen" className="main-user-dropdown-menu-btn">
                                                <button className="main-user-dropdown-menu-btn">
                                                    <span className="main-user-dropdown-menu-icon-span material-symbols-outlined">settings</span>
                                                    <p className="main-user-dropdown-menu-txt-p">Instellingen</p>
                                                </button>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="main-user-dropdown-menu-section">
                                    <ul className="main-user-dropdown-menu-btns-listing-ul">
                                        <li className="main-user-dropdown-menu-btn-item-li">
                                            <button className="main-user-dropdown-menu-btn" onClick={handleLogout}>
                                                <span className="main-user-dropdown-menu-icon-span material-symbols-outlined">logout</span>
                                                <p className="main-user-dropdown-menu-txt-p">Afmelden</p>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        )}
                    </div>

                </li>
            </ul>
        </nav>
    );
};

export default TopNavBar;