import axios from "axios";
import { CreateContactRequest } from "../../models/ContactRequest";
import { apiURL } from "../../devEnv";

export const addContactRequest = async (contactRequest: CreateContactRequest) => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `${apiURL}/contact_requests/`;
        const response = await axios.post(url, contactRequest, config);
        return response.data;
    } catch (error) {
        console.error('Failed to add contact request:', error);
        throw error;
    }
}