import React, { useEffect, useState } from 'react';
import './TerrasWidgetSmall.css';
import { fetchTerraceGrade } from '../../../../../api/weather/WeatherService';

interface TerrasWidgetSmallProps {
    // Define the props for the component here
    city: string;
}

function terraceScore(weatherData: { list: { main: { temp: any; }; wind: { speed: any; }; clouds: { all: any; }; weather: { main: any; }[]; pop: any; }[] }): number {
    let terraceScore = 0;

    // console.log('Forecast data:', weatherData.list);
    // console.log('Pop information:', weatherData.list.map((item: any) => item.pop));

    // Calculate terras score based on different factors ( temperatuur, wind, bewolking, kans op neerslag )

    let totalScore = 0;
    const forecastCount = Math.min(3, weatherData.list.length); // We look at the next 3 hours

    for (let i = 0; i < forecastCount; i++) {
        const forecast = weatherData.list[i];

        // Extract data
        const temp = forecast.main.temp - 273.15; // Convert from Kelvin to Celsius
        const windSpeed = forecast.wind.speed * 3.6; // Convert from m/s to km/h
        const cloudiness = forecast.clouds.all;
        const pop = forecast.pop;

        // Temperature score (starting from 10, minimal reduction)
        let tempScore = 10;
        if (temp < 20) {
            tempScore -= Math.min(2, (20 - temp) * 0.1); // Very mild reduction for cooler temps
        } else if (temp > 30) {
            tempScore -= Math.min(2, (temp - 30) * 0.1); // Very mild reduction for hotter temps
        }


        // Wind score (starting from 10, very mild reduction)
        let windScore = 10;
        if (windSpeed > 20) {
            windScore -= Math.min(3, (windSpeed - 20) * 0.3); // Mild reduction starts when windSpeed is over 20 km/h
        }
        if (windSpeed > 30) {
            windScore -= Math.min(5, (windSpeed - 30) * 0.5); // Stronger reduction starts when windSpeed is over 30 km/h
        }


        // Cloudiness score (starting from 10, very mild reduction)
        let cloudScore = 10;
        if (cloudiness > 50) {
            cloudScore -= Math.min(3, (cloudiness) * 0.05); // Very mild reduction for clouds
        }


        // Precipitation score (starting from 10, mild reduction)
        let rainScore = 10;
        if (pop > 0.1) {
            rainScore -= Math.min(7, Math.pow(pop * 10, 1.5));
        }


        // Calculate the average score for this forecast period
        const averageScore = (tempScore + windScore + cloudScore + rainScore) / 4;
        totalScore += averageScore;

        // Detailed console log explaining each factor's contribution
    }

    // Calculate the overall terrace score based on the average of the forecast periods
    terraceScore = totalScore / forecastCount;

    // Ensure the score is within the range 0 to 10
    terraceScore = Math.floor(Math.max(0, Math.min(10, terraceScore)));

    return terraceScore;
}

const TerrasWidgetSmall: React.FC<TerrasWidgetSmallProps> = (props) => {

    const [terraceGrade, setTerraceGrade] = useState(0);

    useEffect(() => {
        const loadTerraceGrade = async () => {
            const response = await fetchTerraceGrade();
            setTerraceGrade(response);
            console.log('Terrace grade:', response);
        }

        loadTerraceGrade();
    }, []);
    // Implement the component logic here
    const score = Math.round(terraceGrade);
    const total = 10;
    const radius = 80;
    const circumference = 2 * Math.PI * radius;
    const strokeDashoffset = circumference - (score / total) * circumference;
    const rotation = ((score / total) * 180) - 90;

    var color = 'green';

    if (score < 4) {
        color = 'red';
    } else if (score < 7) {
        color = 'orange';
    } else {
        color = 'green';
    }
    return (
        <div className='quick-item'>
            <p className="terraswidget-txt-p">Terrasmetertje</p>
            <p className="terraswidget-grade-txt-p" style={{ color: color }}>{score}/10</p>
            <div className="terraswidget-svg-wrapper">
                <svg className='terraswidget-svg' viewBox="0 0 220 120" preserveAspectRatio="xMidYMid meet">
                    <defs>
                        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" stopColor="red" />
                            <stop offset="50%" stopColor="orange" />
                            <stop offset="100%" stopColor="green" />
                        </linearGradient>
                    </defs>

                    <path
                        d="M 20 100 A 80 80 0 0 1 200 100"
                        fill="none"
                        stroke="url(#gradient)"
                        strokeWidth="20"
                        strokeLinecap="round"
                    />
                    <path
                        d="M 110 100 L 110 10" // Draw a line for the arrow
                        fill="none"
                        stroke="black"
                        strokeWidth="4"
                        strokeLinecap="round"
                        transform={`rotate(${rotation}, 110, 100)`} // Rotate the arrow around (110, 100)
                    />
                    <circle
                        cx="110"
                        cy="100"
                        r="8"
                        fill="black"
                    />
                </svg>
            </div>
        </div>
    );
};

export default TerrasWidgetSmall;