import React from 'react';
import './PaymentInfoView.css';
import logo from '../../../../assets/img/website-10things-logo.png';
import { getSubsidiary } from '../../../../api/auth/user-info';
import { Product } from '../../../../models/Product';
import { SubscriptionType } from '../../../../enums/SubscriptionType';
import { ProductType } from '../../../../enums/ProductType';

interface PaymentInfoViewProps {
    // Add any props you need for the component here
    transactionId: string;
    product: Product;
    productAmount?: number;
    amount: number;
    vat: number;
    total: number;
}

enum RecurringType {
    MONTHLY = 1,
    ANNUAL = 2,
    ONE_TIME = 3
}

const PaymentInfoView: React.FC<PaymentInfoViewProps> = (props) => {
    // Add your component logic here
    const amountInEuro = props.amount.toLocaleString('de-DE', {
        minimumFractionDigits: 2, // Adjust as needed for decimal places
        maximumFractionDigits: 2, // Adjust as needed for decimal places
    });

    const vatInEuro = props.vat.toLocaleString('de-DE', {
        minimumFractionDigits: 2, // Adjust as needed for decimal places
        maximumFractionDigits: 2, // Adjust as needed for decimal places
    });

    const totalInEuro = props.total.toLocaleString('de-DE', {
        minimumFractionDigits: 2, // Adjust as needed for decimal places
        maximumFractionDigits: 2, // Adjust as needed for decimal places
    });

    const currentDate = new Date();

    const getRenewalDate = () => {
        switch (props.product.recurring_type) {
            case SubscriptionType.MONTHLY:
                currentDate.setMonth(currentDate.getMonth() + 1);
                return currentDate.toLocaleDateString();
            case SubscriptionType.ANNUAL:
                currentDate.setFullYear(currentDate.getFullYear() + 1);
                return currentDate.toLocaleDateString();
            default:
                return null;
        }
    };

    const getStartDate = () => {
        switch (props.product.recurring_type) {
            case SubscriptionType.MONTHLY:
                currentDate.setDate(currentDate.getDate() + 7);
                return currentDate.toLocaleDateString();
            case SubscriptionType.ANNUAL:
                currentDate.setDate(currentDate.getDate() + 7);
                return currentDate.toLocaleDateString();
            default:
                return null;
        }
    }

    const subsidiary = getSubsidiary();

    return (
        <div>
            <h1 className="payment-info-title-h1">{props.product.product_type! === ProductType.SUBSCRIPTION ? 'Start je proefabonnement' : 'Koop 10-Things credits.'}</h1>
            <p className="payment-info-subtitle-p">{props.product.product_type! === ProductType.SUBSCRIPTION ? 'Over 7 dagen verloopt je proefabonnement en gaat je abonnement van start.' : 'Met je credits kun je voor elke keer een rapport of briefing aanmaken.'}</p>
            <p className="payment-invoice-info-txt-p"><b>Bedrijfsnaam:</b> {subsidiary.name}</p>
            <ul className="payment-products-listing">
                <li className="payment-product-item-li">
                    <div className="payment-product-img-area">
                        <div className="payment-app-icon-div">
                            <img src={logo} alt="" className="payment-product-img" />
                        </div>
                    </div>
                    <div className="payment-product-info-area">
                        <p className="payment-product-item-name-p">{props.product.product_type! === ProductType.SUBSCRIPTION ? `${props.product.name}`: `${props.productAmount} x ${props.product.name}`}</p>
                        <p className="payment-product-item-info-p">{props.product.description}</p>
                        {props.product.product_type! === ProductType.SUBSCRIPTION && <p className="payment-product-item-renew-p"><b>Abonnement start op {getStartDate()}</b></p>}
                        {props.product.product_type! === ProductType.SUBSCRIPTION && <p className="payment-product-item-renew-p"><b>Wordt verlengd op {getRenewalDate()}</b></p>}
                    </div>
                    <div className="payment-product-pricing-area">
                        <p className="payment-product-item-total-p">€ {amountInEuro}</p>
                        <p className="payment-product-item-btw-p">Inclusief btw op 21% <span className="payment-product-item-btw-amount-span">€ {vatInEuro}</span></p>
                    </div>
                </li>
            </ul>
            <div className="payment-total-area">
                <div className="payment-total-bar-area">
                    <div className="payment-total-left-area">
                        <p className="payment-total-left-p">Subtotaal</p>
                    </div>
                    <div className="payment-total-right-area">
                        <p className="payment-total-right-p">€ {amountInEuro}</p>
                    </div>
                </div>
                <div className="payment-total-bar-area">
                    <div className="payment-total-left-area">
                        <p className="payment-total-left-p">BTW 21%</p>
                    </div>
                    <div className="payment-total-right-area">
                        <p className="payment-total-right-p">€ {vatInEuro}</p>
                    </div>
                </div>
                <div className="payment-total-bar-area">
                    <div className="payment-total-left-area">
                        <p className="payment-total-left-p total-txt">Totaal</p>
                    </div>
                    <div className="payment-total-right-area">
                        <p className="payment-total-right-p total-txt">€ {totalInEuro}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentInfoView;