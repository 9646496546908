import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HistoryProvider } from './Providers/HistoryProvider';
import HomeView from './views/HomeView/HomeView';
import ContactView from './views/ContactView/ContactView';
import PreOrderView from './views/PreOrderView/PreOrderView';
import RegisterView from './views/RegisterView/RegisterView';
import FAQView from './views/FAQView/FAQView';
import PortalView from './views/PortaalViews/PortaalView/PortaalView';
import PaymentView from './views/PaymentView/PaymentView';
import LoginView from './views/LoginView/LoginView';
import PaymentCanceledView from './views/PaymentCanceledView/PaymentCanceledView';
import PayedPaymentView from './views/PayedPaymentView/PayedPaymentView';
import GeneralTermsView from './views/GeneralTermsView/GeneralTermsView';
import PrivacyPolicyView from './views/PrivacyPolicyView/PrivacyPolicyView';
import ForgotPasswordView from './views/ForgotPasswordView/ForgotPasswordView';
import UnsubscribeEmailView from './views/UnsubscribeEmailView/UnsubscribeEmailView';
import { Switch } from '@nextui-org/react';
import { fetchLanguages } from './api/Languages/LanguagesService';
import { Language } from './models/Language';

const RoutesApp: React.FC = () => {
    const [langs, setLangs] = React.useState<Language[]>([]);

    const [currentLanguage, setCurrentLanguage] = React.useState<string>('nl');

    React.useEffect(() => {
        const fetchLangs = async () => {
            try {
                const res = await fetchLanguages();
                console.log(res);
                setLangs(res);
            } catch (error) {
                console.log(error);
            } finally {
                console.log('done');
            }
        }
        fetchLangs();
    }, []);

    const handleLanguageChange = (language: string) => {
        setCurrentLanguage(language);
    }

    return (
        <HistoryProvider>
            <Routes>
                <Route path="/" element={<HomeView />} />
                <Route path="/#features" element={<HomeView />} />
                <Route path="/#pricing" element={<HomeView />} />
                <Route path="/contact" element={<ContactView />} />
                <Route path="/register" element={<RegisterView />} />
                <Route path="/pre-order" element={<PreOrderView />} />
                <Route path="/faq" element={<FAQView />} />
                <Route path="/portaal/*" element={<PortalView />} />
                <Route path="/betalen" element={<PaymentView />} />
                <Route path="/login" element={<LoginView />} />
                <Route path="/betaling-geannuleerd" element={<PaymentCanceledView />} />
                <Route path="/betaald" element={<PayedPaymentView />} />
                <Route path="/algemene-voorwaarden" element={<GeneralTermsView />} />
                <Route path="/privacy-overeenkomst" element={<PrivacyPolicyView />} />
                <Route path="/wachtwoord-vergeten" element={<ForgotPasswordView />} />
                <Route path="/unsubscribe" element={<UnsubscribeEmailView />} />
            </Routes>
        </HistoryProvider>
    );
};

export default RoutesApp;