import api from "../auth/api";
import { getSubsidiary } from "../auth/user-info";

export const fetchTerraceGrade = async () => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };

    try {
        const subsidiary = getSubsidiary();
        const url = `/weather/terrace_grade/${subsidiary.city}/`;
        const response = await api.get(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch terrace grade:', error);
        throw error;
    }
}