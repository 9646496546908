import React from 'react';
import './NoCreditsModal.css';
import { CreditsIcon } from '../../../../assets/icons/CreditsIcon/CreditsIcon';
import { Link } from 'react-router-dom';

interface NoCreditsModalProps {
    handleClose: () => void;
    handleOpenBuyCreditsModal?: () => void;
}

const NoCreditsModal: React.FC<NoCreditsModalProps> = ({ handleClose, handleOpenBuyCreditsModal }) => {

    const stopPropagation = (e: React.MouseEvent) => {
        e.stopPropagation();
    }

    return (
        <div className='nocredits-overlay-div' onClick={handleClose}>
            <div className="nocredits-wrapper-div">
                <div className="nocredits-inner-div" onClick={stopPropagation}>
                    <div className="nocredits-top-area">
                        <button className="nocredits-close-btn" onClick={handleClose}>
                            <span className="material-symbols-outlined">close</span>
                        </button>
                    </div>

                    <div className="nocredits-content-area">
                        <CreditsIcon className='nocredits-icon' />
                        <h2 className="nocredits-title">Geen credits beschikbaar</h2>
                        <p className="nocredits-text">Je hebt niet genoeg credits om deze actie uit te voeren.</p>
                    </div>
                    <button className="nocredits-buy-credits-btn" onClick={handleOpenBuyCreditsModal}>
                        Koop credits
                    </button>
                </div> 
            </div>
        </div>
    );
};

export default NoCreditsModal;