import React from 'react';
import PageLayout from '../../components/Site/PageLayout/PageLayout';
import './ContactView.css';
import { Input, Textarea } from '@nextui-org/react';
import { Helmet } from 'react-helmet-async';
import usePageTracking from '../../utils/usePageTracking';
import { CreateContactRequest } from '../../models/ContactRequest';
import { addContactRequest } from '../../api/contact_request/ContactRequestServices';

interface ContactViewProps {
  // Define any props you need for the ContactView component
}

const ContactView: React.FC<ContactViewProps> = () => {
  usePageTracking();

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const [name, setName] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [phoneNumber, setPhoneNumber] = React.useState<string>('');
  const [message, setMessage] = React.useState<string>('');

  const [nameError, setNameError] = React.useState<string>('');
  const [emailError, setEmailError] = React.useState<string>('');
  const [phoneNumberError, setPhoneNumberError] = React.useState<string>('');
  const [messageError, setMessageError] = React.useState<string>('');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isSubmitting) return;

    var error = false;

    if (name === '') {
      setNameError('Vul je naam in');
      error = true;
    } else {
      setNameError('');
    }

    if (email === '') {
      setEmailError('Vul je email in');
      error = true;
    } else {
      setEmailError('');
    }

    if (phoneNumber === '') {
      setPhoneNumberError('Vul je telefoonnummer in');
      error = true;
    } else {
      setPhoneNumberError('');
    }

    if (message === '') {
      setMessageError('Vul je bericht in');
      error = true;
    } else {
      setMessageError('');
    }

    if (error) return;
    try {
      const newContactRequest: CreateContactRequest = {
        name: name,
        email: email,
        phone_number: phoneNumber,
        message: message,
      };
      const response = await addContactRequest(newContactRequest);

      console.log('Contact request submitted:', response);

    } catch (e) {
      console.error('Failed to submit contact form:', e);
    } finally {
      setName('');
      setEmail('');
      setPhoneNumber('');
      setMessage('');
      setIsSubmitting(false);
    }
  };

  return (
    <PageLayout>
      <Helmet>
        <title>10-Things | Contact</title>
      </Helmet>
      <section className="contact-section">
        <h1 className="contact-title-h1">Neem contact op</h1>
        <p className="contact-txt-p">Heb je vragen of wil je meer informatie over 10-Things? Neem dan contact met ons op via het onderstaande formulier.</p>
        <form className="contact-form" onSubmit={handleSubmit}>
          <Input className='contact-input' type="text" label="Naam" value={name} isInvalid={nameError != ''} errorMessage={nameError} onChange={(e) => setName(e.target.value)} variant="bordered" />
          <Input className='contact-input' type="email" label="E-mail" value={email} isInvalid={emailError != ''} errorMessage={emailError} onChange={(e) => setEmail(e.target.value)} variant="bordered" />
          <Input className='contact-input' type="phone" label="Telefoon" value={phoneNumber} isInvalid={phoneNumberError != ''} errorMessage={phoneNumberError} onChange={(e) => setPhoneNumber(e.target.value)} variant="bordered" />
          <Textarea
            className='contact-input'
            label="Bericht"
            placeholder="Schrijf hier je vragen of opmerkingen"
            variant="bordered"
            value={message}
            isInvalid={messageError != ''}
            errorMessage={messageError}
            onChange={(e) => setMessage(e.target.value)}
            minRows={10}
          />
          <p className="contact-accept-privacy">Door dit formulier te versturen ga je akkoord met onze Algemene Voorwaarden.</p>
          <button type="submit" className="contact-submit-btn">Verstuur</button>
        </form>
      </section>
    </PageLayout>
  );
};

export default ContactView;