import React, { act, useEffect, useState } from 'react';
import PortaalPageLayout from '../../../components/Portaal/PageLayout/PageLayout';
import TopWidgets from '../../../components/Portaal/Dashboard/TopWidgets/TopWidgets';
import ThingsList from '../../../components/Portaal/ThingsList/ThingsList';
import { useNavigate } from 'react-router-dom';
import { checkLocalStorageItems } from '../../../api/auth/user.auth';
import { fetchThingTypes } from '../../../api/things/thingsService';
import ModalLayout from '../../../components/Portaal/ModalLayout/ModalLayout';
import AddPlanThingModal from '../../../components/Portaal/Things/AddPlanThingModal/AddPlanThingModal';
import CompleteThingModal from '../../../components/Portaal/Things/CompleteThingModal/CompleteThingModal';
import NoteOverlay from '../../../components/Portaal/Dashboard/NoteOverlay/NoteOverlay';
import ActionOptionsOverlay from '../../../components/Portaal/Action/ActionOptionsOverlay/ActionOptionsOverlay';
import { Action } from '../../../models/Action';
import AddThingModal from '../../../components/Portaal/Things/AddThingModal/AddThingModal';
import AddVIPModal from '../../../components/Portaal/VIPWidget/AddVIPModal/AddVIPModal';
import PreBriefingModal from '../../../components/Portaal/Dashboard/PreBriefingModal/PreBriefingModal';
import { fetchActiveShift } from '../../../api/shift/shiftService';
import { Shift } from '../../../models/Shift';
import { Vip, VipBriefing } from '../../../models/Vip';
import DeBriefingModal from '../../../components/Portaal/Dashboard/DeBriefingModal/DeBriefingModal';
import { Note } from '../../../models/Note';
import { updateNoteDB } from '../../../api/notes/notesService';
import { ModalOption } from '../../../models/ModalOption';
import OptionsModal from '../../../components/Portaal/OptionsModal/OptionsModal';
import DeletePopup from '../../../components/Portaal/DeletePopup/DeletePopup';
import { deleteVipDB } from '../../../api/vip/VipService';
import EditVIPModal from '../../../components/Portaal/VIPWidget/EditVIPModal/EditVIPModal';
import NoShift from '../../../components/Portaal/Dashboard/NoShift/NoShift';
import { Helmet } from 'react-helmet-async';
import usePageTracking from '../../../utils/usePageTracking';
import CreditsBar from '../../../components/Portaal/Dashboard/CreditsBar/CreditsBar';
import BuyCreditsModal from '../../../components/Portaal/BuyCreditsModal/BuyCreditsModal';
import NoCreditsModal from '../../../components/Portaal/Dashboard/NoCreditsModal/NoCreditsModal';
import { Subscription } from '../../../models/Subscription';

interface DashboardViewProps {
    toggleNav: () => void;
    isNavOpen: boolean;
    activeShift: Shift | null;
    setActiveShift: React.Dispatch<React.SetStateAction<Shift | null>>;
    actions: Action[];
    setActions: React.Dispatch<React.SetStateAction<Action[]>>;
    vips: Vip[];
    setVips: React.Dispatch<React.SetStateAction<Vip[]>>;
    notes: Note[];
    setNotes: React.Dispatch<React.SetStateAction<Note[]>>;
    refreshShiftKey: number;
    setRefreshShiftKey: React.Dispatch<React.SetStateAction<number>>;
    handleOpenBuyCreditsModal: () => void;
    openBuyCreditsModal: boolean;
    setOpenBuyCreditsModal: React.Dispatch<React.SetStateAction<boolean>>;
    activeSubscription: Subscription | null | undefined;
}

const DashboardView: React.FC<DashboardViewProps> = ({toggleNav, isNavOpen, activeShift, setActiveShift, actions, setActions, vips, setVips, notes, setNotes, refreshShiftKey, setRefreshShiftKey, openBuyCreditsModal, setOpenBuyCreditsModal, handleOpenBuyCreditsModal, activeSubscription}) => {
    usePageTracking();
    const navigate = useNavigate();

    checkLocalStorageItems().then((result) => {
        console.log(result);
        if (!result) {
            navigate('/login');
        }
    }
    );

    const hardcodedCredits = 30;



    const refreshShift = () => {
        setRefreshShiftKey(prevKey => prevKey + 1);
    };

    const [thingTypes, setThingTypes] = React.useState<any[]>([]);

    const addActions = async (addingActions: Action[]) => {
        console.log('Adding action:', addingActions);
        setActions([...actions, ...addingActions]);
    };

    const addActiveShift = async (shift: Shift) => {
        setActiveShift(shift);
        setActions(activeShift!.actions);
        setNotes(activeShift!.notes);
        setVips(activeShift!.vips);
    };

    const deleteActions = async (action_ids: number[]) => {
        const updatedActions = actions.filter((action) => !action_ids.includes(action.id));
        setActions(updatedActions);
    };

    const deleteAction = async (action_id: number) => {
        const updatedActions = actions.filter((action) => action.id !== action_id);
        setActions(updatedActions);
    };

    const clearShift = () => {
        setActiveShift(null);
        setActions([]);
        setVips([]);
        setNotes([]);
    };

    const [date, setDate] = React.useState<Date>(new Date());

    console.log(date);

    const formattedDate = date.toISOString().slice(0, 10);


    useEffect(() => {
        const loadThingTypes = async () => {
            try {
                const thingTypes = await fetchThingTypes();
                setThingTypes(thingTypes);
            } catch (err) {
                console.error('Failed to fetch thing types:', err);
            } finally {
                console.log('Done fetching thing types');
            }
        };

        loadThingTypes();
    }, []);

    const [addingThingType, setAddingThingType] = useState<any>(undefined);
    const [openPlanThingModal, setOpenPlanThingModal] = useState<boolean>(false);

    const onClickAddButton = (thingType: any) => {
        setAddingThingType(thingType);
        setOpenPlanThingModal(true);
    };

    const closeAddPlanThingModal = () => {
        setOpenPlanThingModal(false);
    };

    const [openAddThingModal, setOpenAddThingModal] = React.useState<boolean>(false);

    const onAddThingClick = () => {
        setOpenAddThingModal(true);
        console.log('Add thing clicked');
    };

    const onPlanThingClick = (thing: any) => {
    };

    const [refreshThingsKey, setRefreshThingsKey] = useState(0);

    const refreshThings = () => {
        setRefreshThingsKey(prevKey => prevKey + 1);
    };

    const [openCompleteThingModal, setOpenCompleteThingModal] = useState<boolean>(false);

    const [selectedAction, setSelectedAction] = useState<any>(undefined);

    const closeCompleteThingModal = () => {
        setOpenCompleteThingModal(false);
    };

    const onClickAction = (action: any) => {
        console.log(action);
        setSelectedAction(action);
        setOpenCompleteThingModal(true);
    }

    const [openNoteOverlay, setOpenNoteOverlay] = useState<boolean>(false);
    const [selectedNote, setSelectedNote] = useState<any>(undefined);

    const onClickNote = (note: any) => {
        console.log('Note:', note);
        setSelectedNote(note);
        setOpenNoteOverlay(true);
    };

    const onClickAddNoteButton = () => {
        setSelectedNote(null);
        setOpenNoteOverlay(true);
    }

    const updateNote = async (note: Note) => {
        const updatedNotes = notes.map((n: any) => {
            if (n.id === note.id) {
                return { ...n, ...note };
            }
            return n;
        });

        try {
            const response = await updateNoteDB(note.id, {
                note_txt: note.note_txt,
            });
        } catch (error) {
            console.error('Failed to update note:', error);
        } finally {
            console.log('Updated note');
        }

        setNotes(updatedNotes);
    }

    const onDeleteNote = (note_id: number) => {
        const updatedNotes = notes.filter((n: any) => n.id !== note_id);
        setNotes(updatedNotes);
    }

    const onAddNote = (note: any) => {
        setNotes([note, ...notes]);
    }

    const [openActionOptionsOverlay, setOpenActionOptionsOverlay] = useState<boolean>(false);
    const [actionOptionsX, setActionOptionsX] = useState<number>(0);
    const [actionOptionsY, setActionOptionsY] = useState<number>(0);
    const [selectedActionOptionsAction, setSelectedActionOptionsAction] = useState<Action>();

    const onActionOptionsClick = (event: React.MouseEvent, action: Action) => {
        event.stopPropagation();
        const rect = (event.target as HTMLElement).getBoundingClientRect();
        setActionOptionsX(rect.left);
        setActionOptionsY(rect.bottom);
        setOpenActionOptionsOverlay(true);
        setSelectedActionOptionsAction(action);
    };

    const [openPreBriefingModal, setOpenPreBriefingModal] = useState<boolean>(false);

    const [openAddVIPModal, setOpenAddVIPModal] = React.useState<boolean>(false);

    const [openDeBriefingModal, setOpenDeBriefingModal] = React.useState<boolean>(false);

    const DeBriefingModalProps = {
        onClose: () => setOpenDeBriefingModal(false),
        shift: activeShift,
        refreshShiftKey: refreshShiftKey,
        setRefreshShiftKey: setRefreshShiftKey,
        clearShift: clearShift
    };

    const addVip = (vip: Vip) => {
        setVips([...vips, vip]);
    };

    const editVip = (vip: Vip) => {
        const updatedVips = vips.map((v) => {
            if (v.id === vip.id) {
                return vip;
            }
            return v;
        });

        setVips(updatedVips);
    };

    const AddVIPModalProps = {
        onClose: () => setOpenAddVIPModal(false),
        activeShift: activeShift!,
        addVip: addVip
    };

    const [openVipOptions, setOpenVipOptions] = useState<boolean>(false);
    const [vipOptionsX, setVipOptionsX] = useState<number>(0);
    const [vipOptionsY, setVipOptionsY] = useState<number>(0);
    const [selectedVipOptions, setSelectedVipOptions] = useState<VipBriefing | Vip>();
    const [openEditVipModal, setOpenEditVipModal] = useState<boolean>(false);

    const handleVipEdit = () => {
        console.log('Edit vip:', selectedVipOptions);
        setOpenVipOptions(false);
        setOpenEditVipModal(true);
    }

    const handleVipDelete = () => {
        console.log('Delete vip:', selectedVipOptions);
        setOpenVipOptions(false);
        setOpenDeletePopup(true);
    }

    const options: ModalOption[] = [
        {
            label: 'Bewerk',
            icon: 'edit',
            action: handleVipEdit,
            class: ''
        },
        {
            label: 'Verwijder',
            icon: 'delete',
            action: handleVipDelete,
            class: 'delete'
        }
    ];

    const onVipOptionsClick = (event: React.MouseEvent, vip: VipBriefing | Vip) => {
        event.stopPropagation();
        const rect = (event.target as HTMLElement).getBoundingClientRect();
        setVipOptionsX(rect.left);
        setVipOptionsY(rect.bottom);
        setOpenVipOptions(true);
        setSelectedVipOptions(vip);
    };

    const [openDeletePopup, setOpenDeletePopup] = React.useState<boolean>(false);

    const handleDeleteVip = async () => {
        console.log('Delete vip:', selectedVipOptions);
        try {
            const selectedVip = selectedVipOptions as Vip;
            const response = await deleteVipDB(selectedVip.id).then(() => {
                const updatedVips = vips.filter((vip) => vip.id !== selectedVip.id);
                setVips(updatedVips);
                setOpenDeletePopup(false);
            }
            );
            console.log('Deleted Vip: ', response);
        } catch (error) {
            console.error('Failed to delete vip:', error);
        } finally {
            console.log('Deleted Vip');
        }

    };

    const [openNoCreditsModal, setOpenNoCreditsModal] = React.useState(false);

    const handleOpenNoCreditsModal = () => {
        setOpenNoCreditsModal(true);
    };

    const EditVIPModalProps = {
        onClose: () => setOpenEditVipModal(false),
        editVip: editVip,
        vip: selectedVipOptions as Vip
    };

    const PreBriefingModalProps = {
        onClose: () => setOpenPreBriefingModal(false),
        refreshShift: refreshShift,
    };

    const AddThingModalProps = {
        closeAddThingModal: () => setOpenAddThingModal(false),
        thingtype: addingThingType,
        refreshThings: refreshThings
    };

    const ActionOptionsOverlayProps = {
        isOpen: openActionOptionsOverlay,
        x: actionOptionsX,
        y: actionOptionsY,
        selectedAction: selectedActionOptionsAction,
        deleteAction: deleteAction,
        onClose: () => setOpenActionOptionsOverlay(false)
    };

    const NoteOverlayProps = {
        isOpen: openNoteOverlay,
        note: selectedNote,
        onClose: () => setOpenNoteOverlay(false),
        updateNote: updateNote,
        onAddNote: onAddNote,
        onDeleteNote: onDeleteNote
    };

    const CompleteThingModalProps = {
        action: selectedAction,
        closeCompleteThingModal: closeCompleteThingModal
    };

    const AddPlanThingModalProps = {
        thingtype: addingThingType,
        onAddThingClick: onAddThingClick,
        onPlanThingClick: onPlanThingClick,
        refreshKey: refreshThingsKey,
        selectedDate: date,
        closeAddPlanThingModal: closeAddPlanThingModal,
        addActions: addActions
    };

    const PortaalPageLayoutProps = {
        activePage: 'dashboard',
        isNavOpen: isNavOpen,
        toggleNav: toggleNav,
        activeShift: activeShift,
        handleOpenBuyCreditsModal: handleOpenBuyCreditsModal,
        openBuyCreditsModal: openBuyCreditsModal,
        setOpenBuyCreditsModal: setOpenBuyCreditsModal,
        activeSubscription: activeSubscription
    };

    const TopWidgetsProps = {
        onDeleteNote: onDeleteNote,
        onClickAddNoteButton: onClickAddNoteButton,
        onClickNote: onClickNote,
        notes: notes,
        deleteActions: deleteActions,
        addActions: addActions,
        setOpenAddVIPModal: setOpenAddVIPModal,
        setOpenPreBriefingModal: setOpenPreBriefingModal,
        setOpenDeBriefingModal: setOpenDeBriefingModal,
        vips: vips,
        activeShift: activeShift,
        onVipOptionsClick: onVipOptionsClick,
        handleOpenBuyCreditsModal: handleOpenBuyCreditsModal,
        handleOpenNoCreditsModal: handleOpenNoCreditsModal
    };


    const ThingsListProps = {
        thingtypes: thingTypes,
        actions: actions,
        onClickAddButton: onClickAddButton,
        onClickAction: onClickAction,
        onActionOptionsClick: onActionOptionsClick
    };

    return (
        <div>
            <Helmet>
                <title>10-Things | Portaal | Dashboard</title>
            </Helmet>
            {openNoCreditsModal && <NoCreditsModal handleClose={() => setOpenNoCreditsModal(false)} handleOpenBuyCreditsModal={handleOpenBuyCreditsModal} />}
            {openDeletePopup && <DeletePopup title='Weet je zeker dat je deze vip wilt verwijderen?' text='Je kunt dit niet meer terugdraaien!' onDelete={handleDeleteVip} onClose={() => setOpenDeletePopup(false)} />}
            {openVipOptions && <OptionsModal onClose={() => setOpenVipOptions(false)} x={vipOptionsX} y={vipOptionsY} options={options} />}
            {openDeBriefingModal && <DeBriefingModal {...DeBriefingModalProps} />}
            {openPreBriefingModal && <PreBriefingModal {...PreBriefingModalProps} />}
            {openEditVipModal && <EditVIPModal {...EditVIPModalProps} />}
            {openAddVIPModal && <AddVIPModal {...AddVIPModalProps} />}
            {addingThingType && <ModalLayout isOpen={openAddThingModal} title={'Voeg een ' + addingThingType.singular.toLowerCase() + ' toe'} onClose={() => setOpenAddThingModal(false)} zIndex={100005}>
                <AddThingModal {...AddThingModalProps} />
            </ModalLayout>}
            {openActionOptionsOverlay && <ActionOptionsOverlay {...ActionOptionsOverlayProps} />}
            {openNoteOverlay && <NoteOverlay {...NoteOverlayProps} />}
            {openCompleteThingModal && <ModalLayout isOpen={openCompleteThingModal} onClose={() => setOpenCompleteThingModal(false)}>
                <CompleteThingModal {...CompleteThingModalProps} />
            </ModalLayout>}
            {openPlanThingModal && <ModalLayout isOpen={openPlanThingModal} onClose={() => setOpenPlanThingModal(false)}>
                <AddPlanThingModal {...AddPlanThingModalProps} />
            </ModalLayout>}
            <PortaalPageLayout {...PortaalPageLayoutProps} >
                {activeShift ? (
                    <div>
                        <TopWidgets {...TopWidgetsProps} />
                        <ThingsList {...ThingsListProps} />
                    </div>
                ) : (
                    <NoShift setOpenPreBriefingModal={setOpenPreBriefingModal} />
                )}

                {/* <ToDoLists /> */}
            </PortaalPageLayout>
        </div>
    );
};

export default DashboardView;