import React, { useRef, useState } from 'react';
import './RegisterView.css';
import restaurantIMG from '../../assets/img/restaurant.jpg';
import AccountInfo from './InnerViews/AccountInfo/AccountInfo';
import CompanyInfo from './InnerViews/CompanyInfo/CompanyInfo';
import { checkUserExists, registerUser, resendConfirmationCode, verifyUser } from '../../api/users/usersService';
import { Helmet } from 'react-helmet-async';
import AccountVerification from './InnerViews/AccountVerification/AccountVerification';
import { useNavigate } from 'react-router-dom';
import usePageTracking from '../../utils/usePageTracking';

const RegisterView: React.FC = () => {
    usePageTracking();
    const navigate = useNavigate();

    const [step, setStep] = React.useState(1);
    const [userName, setUserName] = React.useState('');

    const [companyName, setCompanyName] = useState('');
    const [errorCompanyName, setErrorCompanyName] = useState('');
    const [street, setStreet] = useState('');
    const [errorStreet, setErrorStreet] = useState('');
    const [houseNumber, setHouseNumber] = useState('');
    const [errorHouseNumber, setErrorHouseNumber] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [errorZipcode, setErrorZipcode] = useState('');
    const [city, setCity] = useState('');
    const [errorCity, setErrorCity] = useState('');
    const [province, setProvince] = useState('');
    const [errorProvince, setErrorProvince] = useState('');
    const [country, setCountry] = useState('');
    const [errorCountry, setErrorCountry] = useState('');

    const handleCompanyInfoValidation = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        console.log('Company info validation');

        let error = false;

        if (companyName === '') {
            setErrorCompanyName('Vul je bedrijfsnaam in!');
            error = true;
        } else {
            setErrorCompanyName('');
        }

        if (street === '') {
            setErrorStreet('Vul je straat in!');
            error = true;
        } else {
            setErrorStreet('');
        }

        if (houseNumber === '') {
            setErrorHouseNumber('Vul je huisnummer in!');
            error = true;
        } else {
            setErrorHouseNumber('');
        }

        if (zipcode === '') {
            setErrorZipcode('Vul je postcode in!');
            error = true;
        } else {
            setErrorZipcode('');
        }

        if (city === '') {
            setErrorCity('Vul je stad in!');
            error = true;
        } else {
            setErrorCity('');
        }

        if (province === '') {
            setErrorProvince('Vul je provincie in!');
            error = true;
        } else {
            setErrorProvince('');
        }

        if (country === '') {
            setErrorCountry('Vul je land in!');
            error = true;
        } else {
            setErrorCountry('');
        }

        if (!error) {

            setStep(2);
        }
    };

    const companyInfoProps = {
        step,
        setStep,
        companyName,
        setCompanyName,
        errorCompanyName,
        street,
        setStreet,
        errorStreet,
        houseNumber,
        setHouseNumber,
        errorHouseNumber,
        zipcode,
        setZipcode,
        errorZipcode,
        city,
        setCity,
        errorCity,
        province,
        setProvince,
        errorProvince,
        country,
        setCountry,
        errorCountry,
        handleCompanyInfoValidation
    };

    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked);
    };

    const [fnameError, setFnameError] = useState('');
    const [lnameError, setLnameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [isCheckedError, setIsCheckedError] = useState('');

    const handleAccountInfoValidation = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let error = false;

        if (fname === '') {
            setFnameError('Vul je voornaam in!');
            error = true;
        } else {
            setFnameError('');
        }
        if (lname === '') {
            setLnameError('Vul je achternaam in!');
            error = true;
        } else {
            setLnameError('');
        }
        if (email === '') {
            setEmailError('Vul je e-mail in!');
            error = true;
        } else {
            await checkUserExists(email).then((response) => {
                if (response.data) {
                    setEmailError('E-mail is al in gebruik!');
                    error = true;
                } else {
                    setEmailError('');
                }
            }
            );
        }
        if (phone === '') {
            setPhoneError('Vul je telefoonnummer in!');
            error = true;
        } else {
            setPhoneError('');
        }
        if (password === '') {
            setPasswordError('Voer een wachtwoord in');
            error = true;
        } else if (password.length < 12) {
            setPasswordError('Wachtwoord moet minimaal 12 karakters bevatten');
            error = true;
        } else if (!/(?=.*?[0-9])/.test(password)) {
            setPasswordError('Wachtwoord moet minimaal 1 nummer bevatten');
            error = true;
        } else if (!/(?=.*?[!@#\$&*~])/.test(password)) {
            setPasswordError('Wachtwoord moet minimaal 1 speciaal karakter bevatten');
            error = true;
        } else if (!/(?=.*?[A-Z])/.test(password)) {
            setPasswordError('Wachtwoord moet minimaal 1 hoofdletter bevatten');
            error = true;
        } else if (!/(?=.*?[a-z])/.test(password)) {
            setPasswordError('Wachtwoord moet minimaal 1 kleine letter bevatten');
            error = true;
        } else {
            setPasswordError('');
        }
        if (confirmPassword === '') {
            setConfirmPasswordError('Bevestig je wachtwoord!');
            error = true;
        } else {
            if (password !== confirmPassword) {
                setConfirmPasswordError('Wachtwoorden komen niet overeen!');
                error = true;
            } else {
                setConfirmPasswordError('');
            }
        }
        if (!isChecked) {
            setIsCheckedError('Accepteer de algemene voorwaarden en privacy overeenkomst!');
            error = true;
        } else {
            setIsCheckedError('');
        }

        if (!error) {
            try {
                console.log('Registering user...');
                const response = await registerUser({
                    firstname: fname,
                    lastname: lname,
                    email: email,
                    password: password,
                    phone_number: phone,
                    company_name: companyName,
                    company_street: street,
                    company_street_number: houseNumber,
                    company_postcode: zipcode,
                    company_city: city,
                    company_province: province,
                    company_country: country
                });
                setUserName(response.username);
                setStep(3);
                console.log(response);
            } catch (error) {
                console.error('Failed to register user:', error);
            } finally {
                console.log('User registered');
            }
        }
    };

    const accountInfoProps = {
        step,
        setStep,
        fname,
        setFname,
        fnameError,
        lname,
        setLname,
        lnameError,
        email,
        setEmail,
        emailError,
        phone,
        setPhone,
        phoneError,
        password,
        setPassword,
        passwordError,
        confirmPassword,
        setConfirmPassword,
        confirmPasswordError,
        isChecked,
        handleCheckboxChange,
        isCheckedError,
        handleAccountInfoValidation
    };

    const [verificationCode, setVerificationCode] = useState<string[]>(['', '', '', '', '', '']);
    const inputsRef = useRef<(HTMLInputElement | null)[]>([]);
    const [verificationError, setVerificationError] = useState('');

    const handleChange = (index: number, value: string) => {
        const updatedVerificationCode = [...verificationCode];
        updatedVerificationCode[index] = value;
        setVerificationCode(updatedVerificationCode);

        // Move to the next input if the current one is filled
        if (value && index < 5) {
            inputsRef.current[index + 1]?.focus();
        }
    };

    const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Backspace' && !verificationCode[index] && index > 0) {
            inputsRef.current[index - 1]?.focus();
        }
    };

    const handleVerification = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        console.log('Verification code:', verificationCode.join(''));
        let error = false;

        if (verificationCode.join('').length < 6) {
            setVerificationError('Vul alle velden in!');
            error = true;
        } else {
            setVerificationError('');
        }

        if (!error) {
            console.log('Verification code:', verificationCode.join(''));
            try {
                const response = await verifyUser(userName, verificationCode.join(''));
                console.log(response);
            } catch (error: any) {
                if (error.response && error.response.status === 404) {
                    setVerificationError('Verificatie code is ongeldig');
                } else {
                    console.error('Failed to verify user:', error);
                    setVerificationError('Er is iets fout gegaan');
                }
            } finally {
                navigate('/login');
            }
        }
    };

    const handleResendConfirmationCode = async () => {
        try {
            console.log('Resending confirmation code...');
            const response = await resendConfirmationCode(userName);
            console.log(response);
        } catch (error) {
            console.error('Failed to resend confirmation code:', error);
        } finally {
            console.log('Confirmation code resent');
        }
    }

    const accountVerificationProps = {
        verificationCode,
        setVerificationCode,
        inputsRef,
        handleChange,
        handleKeyDown,
        handleVerification,
        verificationError,
        handleResendConfirmationCode
    };

    return (
        <div className='register-container'>
            <Helmet>
                <title>10-Things | Account aanmaken</title>
            </Helmet>
            {/* <PopUpLayout onClose={() => console.log('Close')}>
                <VerificationInput />
            </PopUpLayout> */}
            <div className="register-left-area">
                {step === 1 && (<CompanyInfo {...companyInfoProps} />)}
                {step === 2 && (<AccountInfo {...accountInfoProps} />)}
                {step === 3 && (<AccountVerification {...accountVerificationProps} />)}
            </div>
            <div className="register-right-area">
                <img src={restaurantIMG} alt="Register hero" className="register-inner-img" />
            </div>
        </div>
    );
};

export default RegisterView;