import React, { useEffect } from 'react';
import PortaalPageLayout from '../../../components/Portaal/PageLayout/PageLayout';
import './ReportsView.css';
import { fetchDayReportsBySubsidiary } from '../../../api/day_reports/day_reportsService';
import { DayReport } from '../../../models/DayReport';
import { Shifts } from '../../../enums/Shifts';
import { Shift } from '../../../models/Shift';
import { fetchShiftsBySubsidiary } from '../../../api/shift/shiftService';
import PDFViewerModal from '../../../components/Portaal/PDFViewerModal/PDFViewerModal';
import { Subscription } from '../../../models/Subscription';

interface ReportsViewProps {
    toggleNav: () => void;
    isNavOpen: boolean;
    handleOpenBuyCreditsModal: () => void;
    openBuyCreditsModal: boolean;
    setOpenBuyCreditsModal: React.Dispatch<React.SetStateAction<boolean>>;
    activeSubscription: Subscription | null | undefined;
}

const groupReportsByDate = (shifts: Shift[]) => {
    return shifts.reduce((acc, shift) => {
        if (shift.day_report !== null) {
            const date = shift.date || 'Unknown Date';
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(shift);
        }
        return acc;
    }, {} as Record<string, Shift[]>);
};

const getShiftTypeText = (shift: Shifts): string => {
    switch (shift) {
        case Shifts.MORNING:
            return 'Ochtendshift';
        case Shifts.AFTERNOON:
            return 'Middagshift';
        case Shifts.EVENING:
            return 'Avondshift';
        case Shifts.NIGHT:
            return 'Nachtshift';
        default:
            return 'Unknown Shift';
    }
};

const ReportsView: React.FC<ReportsViewProps> = (props) => {
    const [shifts, setShifts] = React.useState<Shift[]>([]);
    const [groupedReports, setGroupedReports] = React.useState<Record<string, Shift[]>>({});

    useEffect(() => {
        const loadReports = async () => {
            console.log('Loading reports');
            try {
                const response = await fetchShiftsBySubsidiary();
                console.log('Reports:', response);
                setShifts(response);
                setGroupedReports(groupReportsByDate(response));
            } catch (error) {
                console.error('Failed to fetch reports:', error);
            }
        };

        loadReports();
    }, []);

    const [pdfModalOpen, setPdfModalOpen] = React.useState(false);
    const [pdfUrl, setPdfUrl] = React.useState('');
    const handleClosePDFModal = () => {
        setPdfModalOpen(false);
    };
    const stopPropagation = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    const handleOpenPDFModal = (url: string) => {
        setPdfUrl(url);
        setPdfModalOpen(true);
    }
    return (
        <div>
            {pdfModalOpen && <PDFViewerModal url={pdfUrl} onClose={handleClosePDFModal} onClick={stopPropagation} />}
            <PortaalPageLayout activePage='reports' isNavOpen={props.isNavOpen} toggleNav={props.toggleNav} handleOpenBuyCreditsModal={props.handleOpenBuyCreditsModal} openBuyCreditsModal={props.openBuyCreditsModal} setOpenBuyCreditsModal={props.setOpenBuyCreditsModal} activeSubscription={props.activeSubscription}>
                <div className="day-reports-overview-list">
                    <div className="day-reports-overview-title-area">
                        <h1 className="day-reports-overview-title-h1">Rapporten</h1>
                    </div>
                    <ul className="day-reports-overview-listing-ul">
                        {Object.entries(groupedReports).map(([date, reports]) => (
                            <React.Fragment key={date}>
                                <li className="day-report-item-li">
                                    <div className="day-report-date-item">
                                        <h5 className="day-report-date-h5">{date}</h5>
                                    </div>
                                </li>
                                {reports.map((shift, index) => (
                                    shift.day_report && (
                                        <li key={index} className="day-report-item-li">
                                            <div className="day-report-item-div">
                                                <div className="day-report-top-view">
                                                    <div className="day-report-top-view-title-area">
                                                        <h3 className="day-report-shift-type-h3">
                                                            {getShiftTypeText(shift.shift_type as Shifts)}
                                                        </h3>
                                                        <div className="day-report-shift-grade-div">
                                                            {shift.day_report.grade !== undefined && shift.day_report.grade !== null ? shift.day_report.grade.toFixed(1) : 'N/A'}
                                                        </div>
                                                    </div>
                                                    <div className="day-report-info-area">
                                                        <div className="day-report-info-item">
                                                            <h5 className="day-report-info-h5">Manager</h5>
                                                            <p className="day-report-info-p">{shift.day_report.manager}</p>
                                                        </div>
                                                        <div className="day-report-info-item">
                                                            <h5 className="day-report-info-h5">Verstuurd op</h5>
                                                            <p className="day-report-info-p">{shift.day_report.date}</p>
                                                        </div>
                                                    </div>
                                                    <div className="day-report-controls-area">
                                                        <a href={shift.day_report.day_report_src} target="_blank" rel="noreferrer">
                                                        <button className="day-report-controls-btn">
                                                            <span className="material-symbols-rounded day-report-control-icon-span">
                                                                download
                                                            </span>
                                                        </button>
                                                        </a>
                                                        <button className="day-report-controls-btn" onClick={() => handleOpenPDFModal(shift.day_report!.day_report_src!)}>
                                                            <span className="material-symbols-rounded day-report-control-icon-span">
                                                                assignment
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                ))}
                            </React.Fragment>
                        ))}
                    </ul>
                </div>
            </PortaalPageLayout>
        </div>
    );
};

export default ReportsView;