import React, { useEffect } from 'react';
import PortaalPageLayout from '../../../components/Portaal/PageLayout/PageLayout';
import './MenuUploadView.css';
import MenuItem from '../../../components/Portaal/MenuUploadView/MenuItem/MenuItem';
import { Menu } from '../../../models/Menu';
import { deleteMenuDB, fetchMenuBySubsidiary } from '../../../api/menu/menuService';
import MenuUploadModal from '../../../components/Portaal/MenuUploadView/MenuUploadModal/MenuUploadModal';
import { CircularProgress } from '@nextui-org/react';
import { Helmet } from 'react-helmet-async';
import usePageTracking from '../../../utils/usePageTracking';
import { Subscription } from '../../../models/Subscription';

interface MenuUploadViewProps {
    toggleNav: () => void;
    isNavOpen: boolean;
    handleOpenBuyCreditsModal: () => void;
    openBuyCreditsModal: boolean;
    setOpenBuyCreditsModal: React.Dispatch<React.SetStateAction<boolean>>;
    activeSubscription: Subscription | null | undefined;
}

const MenuUploadView: React.FC<MenuUploadViewProps> = (props) => {
    usePageTracking();
    const [menus, setMenus] = React.useState<Menu[]>([]);

    const addMenu = (menu: Menu) => {
        setMenus([...menus, menu]);
    }

    const deleteMenu = (menuId: number) => {
        setMenus(menus.filter(menu => menu.id !== menuId));
    }

    const [isFormOpen, setIsFormOpen] = React.useState(false);

    const handleAddMenuClick = () => {
        setIsFormOpen(true);
    }

    const onClose = () => {
        setIsFormOpen(false);
    }

    useEffect(() => {
        const loadMenus = async () => {
            try {
                const menus = await fetchMenuBySubsidiary();
                console.log(menus);
                setMenus(menus);
            } catch (err) {
                console.error('Failed to fetch menus:', err);
            } finally {
                console.log('Done fetching menus');
            }
        }

        loadMenus();
    }, []);

    const handleDeleteMenu = async (menuId: number) => {
        try {
            await deleteMenuDB(menuId);
            deleteMenu(menuId);
        } catch (error) {
            console.error('Failed to delete menu:', error);
        }
    }

    return (
        <div>
            <Helmet>
                <title>10-Things | Portaal | Menu Uploaden</title>
            </Helmet>
            {isFormOpen && <MenuUploadModal onClose={onClose} addMenu={addMenu} />}
            <PortaalPageLayout activePage='menuupload' isNavOpen={props.isNavOpen} toggleNav={props.toggleNav} handleOpenBuyCreditsModal={props.handleOpenBuyCreditsModal} openBuyCreditsModal={props.openBuyCreditsModal} setOpenBuyCreditsModal={props.setOpenBuyCreditsModal} activeSubscription={props.activeSubscription}>
                <div className="menu-upload-container">
                    <div className="menu-upload-inner-div">
                        <nav id="menu-upload-top-nav">
                            <ul className="menu-upload-top-nav-listing-ul">
                                <li className="menu-upload-top-nav-title-li">
                                    <h1 className='menu-upload-title-h1'>Menu's uploaden</h1>
                                </li>
                                <li className="menu-upload-top-nav-add-menu-btn-li">
                                    <button className="menu-upload-top-nav-add-menu-btn" onClick={handleAddMenuClick}><span className="menu-upload-icon-span material-symbols-outlined">add</span></button>
                                </li>
                            </ul>
                        </nav>
                        <div className="menu-upload-content-div">
                            {menus.map((menu, index) => (
                                <div className="menu-upload-item">
                                    <div className="menu-upload-title-area">
                                        <h3 className="menu-upload-title-item-h3">{menu.menu_type.name}</h3>
                                        <button className="menu-upload-delete-btn" type='button' onClick={() => handleDeleteMenu(menu.id)}>
                                            <span className="menu-upload-delete-icon-span material-symbols-outlined">remove</span>
                                        </button>
                                    </div>
                                    {menu.is_complete ?
                                        <ul className="menu-upload-content-listing-ul">
                                            {menu.menu_details.map((menu_item, index) => (
                                                <MenuItem key={index} menuDetail={menu_item} />
                                            ))}
                                        </ul> :
                                        <div className="menu-upload-incomplete-div">
                                            <CircularProgress classNames={{
                                                svg: 'w-10 h-10',
                                            }} />
                                        </div>
                                    }

                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </PortaalPageLayout>
        </div>
    );
};

export default MenuUploadView;