import axios from "axios";
import { Language } from "../../models/Language";
import api from "../auth/api";

export const fetchLanguages = async (): Promise<Language[]> => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = '/languages/';
        const response = await api.get(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch languages:', error);
        throw error;
    }
}