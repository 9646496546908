import React, { useRef, useState } from 'react';
import './LoginView.css';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { loginUser } from '../../api/auth/user.auth';
import { getSubsidiary } from '../../api/auth/user-info';
import usePageTracking from '../../utils/usePageTracking';
import LoginForm from './InnerViews/LoginForm/LoginForm';
import { getUsernameByEmail, resendConfirmationCode, verifyUser } from '../../api/users/usersService';
import LoginVerification from './InnerViews/LoginVerification/LoginVerification';

const LoginView: React.FC = () => {
    usePageTracking();
    const navigate = useNavigate();
    // const location = useLocation();

    // const queryParams = new URLSearchParams(location.search);
    // const ref = queryParams.get('ref');

    const [showLogin, setShowLogin] = useState(true);
    const [showVerification, setShowVerification] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let isValid = true;

        if (!email) {
            setEmailError('Je moet wel een e-mailadres invullen!');
            isValid = false;
        } else {
            if (!emailRegex.test(email)) {
                setEmailError('Je moet wel een geldig e-mailadres invullen!');
                isValid = false;
            } else {
                setEmailError('');
            }
        }

        if (!password) {
            setPasswordError('Je moet wel een wachtwoord invullen!');
            isValid = false;
        } else {
            setPasswordError('');
        }

        if (isValid) {
            // Do something with the data
            setIsLoading(true);

            loginUser(email, password)
                .then(data => {
                    console.log(data);
                    if (getSubsidiary() === null) {
                        navigate('/portaal/voeg-vestiging-toe');
                    } else {
                        navigate('/portaal/dashboard');
                    }
                    setIsLoading(false);
                })
                .catch(error => {
                    // Handle the error...

                    console.log('Error:', error);


                    if (error.status == 404) {
                        setPasswordError('Je e-mail of wachtwoord komt niet overeen!');
                    }

                    if (error.status == 403) {
                        getUsername(email);
                        setShowLogin(false);
                        setShowVerification(true);
                    }

                    setIsLoading(false);

                });
        }

    };

    const getUsername = async (email: string) => {
        try {
            const response = await getUsernameByEmail(email);
            console.log('Username:', response.data);
            setUsername(response.data);
            const resendConfirmation = await resendConfirmationCode(response.data);
        } catch (error) {
            console.error('Failed to get username by email:', error);
        } finally {
        }
    }

    const loginProps = {
        handleSubmit,
        email,
        handleEmailChange,
        emailError,
        password,
        handlePasswordChange,
        passwordError,
        isLoading,
    }

    const [verificationCode, setVerificationCode] = useState<string[]>(['', '', '', '', '', '']);
    const inputsRef = useRef<(HTMLInputElement | null)[]>([]);
    const [verificationError, setVerificationError] = useState('');

    const handleChange = (index: number, value: string) => {
        const updatedVerificationCode = [...verificationCode];
        updatedVerificationCode[index] = value;
        setVerificationCode(updatedVerificationCode);

        // Move to the next input if the current one is filled
        if (value && index < 5) {
            inputsRef.current[index + 1]?.focus();
        }

    };

    const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Backspace' && !verificationCode[index] && index > 0) {
            inputsRef.current[index - 1]?.focus();
        }
    };

    const handleVerification = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        console.log('Verification code:', verificationCode.join(''));
        let error = false;

        if (verificationCode.join('').length < 6) {
            setVerificationError('Vul alle velden in!');
            error = true;
        } else {
            setVerificationError('');
        }

        if (!error) {
            setIsLoading(true);
            console.log('Verification code:', verificationCode.join(''));
            try {
                const response = await verifyUser(username, verificationCode.join(''));
                console.log(response);
            } catch (error: any) {
                if (error.response && error.response.status === 404) {
                    setVerificationError('Verificatie code is ongeldig');
                } else {
                    console.error('Failed to verify user:', error);
                    setVerificationError('Er is iets fout gegaan');
                }
                setIsLoading(false);
            } finally {
                setIsLoading(false);
                loginUser(email, password)
                    .then(data => {
                        console.log(data);
                        if (getSubsidiary() === null) {
                            navigate('/portaal/voeg-vestiging-toe');
                        } else {
                            navigate('/portaal/dashboard');
                        }
                        setIsLoading(false);
                    })
                    .catch(error => {
                        // Handle the error...

                        console.log('Error:', error);

                        if (error.status == 404) {
                            setPasswordError('Je e-mail of wachtwoord komt niet overeen!');
                        }

                        if (error.status == 403) {
                            getUsername(email);
                            setShowLogin(false);
                            setShowVerification(true);
                        }

                        setIsLoading(false);

                    });
            }
        }
    };

    const handleResendConfirmationCode = async () => {
        try {
            console.log('Resending confirmation code...');
            const response = await resendConfirmationCode(username);
            console.log(response);
        } catch (error) {
            console.error('Failed to resend confirmation code:', error);
        } finally {
            console.log('Confirmation code resent');
        }
    }

    const loginVerificationProps = {
        verificationCode,
        setVerificationCode,
        inputsRef,
        handleChange,
        handleKeyDown,
        verificationError,
        handleVerification,
        handleResendConfirmationCode,
        isLoading
    };

    return (
        <div className='login-view'>
            <Helmet>
                <title>10-Things | Inloggen</title>
            </Helmet>
            <a href="/">
                <button className="main-goback-butt">
                    <span className="main-goback-icon-span material-symbols-outlined">arrow_back</span>
                </button>
            </a>
            <div id='inloggen-wrapper-div'>
                <div id='inloggen-inner-page'>
                    <div id='inloggen-login-view-div'>
                        <div id="inloggen-login-logo-area">
                            <img src="../assets/img/website-10things-logo.png" alt="" className="inloggen-login-logo" />
                        </div>

                        <div id="inloggen-login-form-area-div">
                            {showLogin && <LoginForm {...loginProps} />}
                            {showVerification && <LoginVerification {...loginVerificationProps} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginView;